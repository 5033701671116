import {
  Backdrop,
  Checkbox,
  Fade,
  Input,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Button,
  FormHelperText,
  Box,
  Table as Tb
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/system";
import { RemoveCircleRounded } from "@mui/icons-material";
import { DatePicker, getISODate, GhostButton } from "./../../sdk";
import { Select, TContainer, TextFieldLarge } from "./../Styles";

export const Table = styled(Tb)`
  thead {
    th {
      font: normal normal bold 16px/20px Roboto;
      letter-spacing: 0px;
      color: #130453;
      border-right: 30px solid transparent;
    }
  }
  tbody {
    td {
      text-align: left;
      font: normal normal normal 18px/22px Roboto;
      letter-spacing: 0px;
      color: #fff;
      > div {
        margin: 15px 30px 15px 0;
      }
    }
  }
`;

export const PreciumModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    position: relative;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    padding: 25px 40px;
    outline: 0;
    z-index: 1;
    .header {
      margin-top:10px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      .title {
        margin-top:10px;
        font: normal normal 600 16px/20px Roboto;
        letter-spacing: 0px;
        color: #333333;
        text-align: center;
      }
      button {
        position: absolute;
        top: 10px;
        right: 4px;
        rgb(48, 111, 188);
        font-size: 18px;
      }
    }
    .body {
      margin-top: 20px;
    }
    .footer {
      margin-top: 30px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const DmdLevel = styled(Box)`
  width: 80px;
`;

export const RemoveButton = styled(RemoveCircleRounded)`
  color: #210d79;
  cursor: pointer;
  margin-top: 4px;
`;

export const TableNew = styled(Table)`
  thead {
    th {
      font: normal normal bold 16px/20px Roboto;
      letter-spacing: 0px;
      color: rgb(48, 111, 188);
      border-right: 18px solid transparent;
    }
  }
  tbody {
    td {
      text-align: left;
      font: normal normal normal 16px/20px Roboto;
      letter-spacing: 0px;
      color: #000000;
      > Box {
        margin: 15px 20px 15px 0;
      }
    }
  }
`;

const DAYS = [
  {
    name: "All",
    value: -1,
  },
  {
    name: "Sunday",
    value: 0,
  },
  {
    name: "Monday",
    value: 1,
  },
  {
    name: "Tuesday",
    value: 2,
  },
  {
    name: "Wednesday",
    value: 3,
  },
  {
    name: "Thursday",
    value: 4,
  },
  {
    name: "Friday",
    value: 5,
  },
  {
    name: "Saturday",
    value: 6,
  },
];

export function EditDemandLevelModal({
    isOpen,
    data,
    demandLevels,
    onSave,
    setSetModalOpen,
}) {
  const [option, setOption] = useState({
    startDate: new Date(
        data.date
    ),
    endDate: new Date(
        data.date
    ),
    days: [new Date(data.date).getDay()],
    hotelDemandLevelSymbol: data?.calculationSettings?.demandLevelDLF?.symbol??'',
    cityDemandLevelSymbol: data?.calculationSettings?.cityDemandLevelDLF?.symbol??'',
    overrideSymbol: false,
    cityOverrideSymbol: data?.calculationSettings?.cityDemandLevelDLF?.symbol ? true : false,
  });
  console.log(option)
  const scrollRef = useRef(null);
  useEffect(() => {
    setOption(
      {
        startDate: new Date(
            data.date
        ),
        endDate: new Date(
            data.date
        ),
        days: [new Date(data.date).getDay()],
        hotelDemandLevelSymbol: data?.calculationSettings?.demandLevelDLF?.symbol??'',
        cityDemandLevelSymbol: data?.calculationSettings?.cityDemandLevelDLF?.symbol??'',
        overrideSymbol: false,
        cityOverrideSymbol: data?.calculationSettings?.cityDemandLevelDLF?.symbol ? true : false,
      });
  }, [data]);
  function isValid() {
    const {
      startDate,
      endDate,
      days,
    } = option;

    return (
      startDate &&
      endDate &&
      days &&
      new Date(endDate).setHours(0, 0, 0) >=
        new Date(startDate).setHours(0, 0, 0)
    );
  }

  function onValueUpdate(key, value) {
    if (key === "startDate" || key === "endDate") {
      value = getISODate(value);
    }
    value =
      key === "overrideSymbol" ||
      key === "cityOverrideSymbol"
        ? value.checked
        : value;
    setOption((prevState) =>({
        ...prevState,
        [key]: value,
    })
    );
  }
  function onSaveClick() {
    const newArray =[{
        startDate: option.startDate,
        endDate: option.endDate,
        days: option.days,
        hotelDemandLevelSymbol: option.hotelDemandLevelSymbol,
        cityDemandLevelSymbol: option.cityOverrideSymbol
        ? option.cityDemandLevelSymbol
        : "",
        overrideSymbol: option.overrideSymbol,
        cityOverrideSymbol: option.cityOverrideSymbol
    }];
    onSave(newArray.filter(isValid));
    setOption({});
    setSetModalOpen(false);
  }

  function getClose() {
    setOption({});
    setSetModalOpen(false);
  }
  return (
    <PreciumModal
      aria-labelledby="Set City Demand Level"
      aria-describedby="Utility to set city demand level in batches by selecting dates and days"
      open={isOpen}
      onClose={getClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Box className="container">
          <Box className="header">
            <Box className="title">Set City Demand Levels</Box>
            <GhostButton onClick={getClose}>X</GhostButton>
          </Box>
          <Box
            className="body"
            style={{ maxHeight: "240px", overflowX: "auto" }}
          >
            <TContainer component={Paper}>
              <TableNew stickyHeader>
                <thead>
                  <tr>
                    <th>Start date</th>
                    <th>End date</th>
                    <th>Days Selection</th>
                    <th style={{ textAlign: "left" }}>City Demand Level</th>
                  </tr>
                </thead>
                <tbody>
                    <tr style={{ height: "90px" }}>
                      <td>
                        <DatePicker
                          date={new Date(option.startDate)}
                          onChange={(startDate) =>
                            onValueUpdate("startDate", startDate)
                          }
                        />
                      </td>
                      <td>
                        <DatePicker
                          date={new Date(option.endDate)}
                          onChange={(endDate) =>
                            onValueUpdate("endDate", endDate)
                          }
                        />
                      </td>
                      <td>
                        <Select
                          label="Days"
                          multiple
                          value={option.days}
                          onChange={(e) => {
                            const { value } = e.target;
                            if (value.indexOf(-1) !== -1) {
                              onValueUpdate("days", [0, 1, 2, 3, 4, 5, 6]);
                              return;
                            }
                            onValueUpdate("days", value);
                          }}
                          input={<Input />}
                          MenuProps={{ getContentAnchorEl: null }}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <em>Select Days</em>;
                            }
                            return DAYS.filter(
                              (day) => selected.indexOf(day.value) !== -1
                            )
                              .map((day) => day.name.substring(0, 3))
                              .join(", ");
                          }}
                          style={{ width: "130px" }}
                        >
                          {DAYS.map(({ name, value }) => (
                            <MenuItem key={name} value={value}>
                              <ListItemText primary={name} />
                              {value !== -1 && (
                                <Checkbox
                                  checked={option.days.indexOf(value) > -1}
                                />
                              )}
                            </MenuItem>
                          ))}
                        </Select>
                      </td>
                      <td style={{ paddingTop: "0px" }}>
                        <Box style={{ display: "flex", alignItems: "start" }}>
                        {option.cityOverrideSymbol ?
                          (
                              <DmdLevel
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Select
                                  label="Demand Level"
                                  value={option.cityDemandLevelSymbol}
                                  onChange={(e) => {
                                    onValueUpdate(
                                      "cityDemandLevelSymbol",
                                      e.target.value
                                    );
                                  }}
                                  input={<Input />}
                                  MenuProps={{ getContentAnchorEl: null }}
                                  style={{ width: "50px" }}
                                  error={option.cityDemandLevelSymbol === ""}
                                  helperText={true ? "Invalid Entry" : ""}
                                >
                                  {demandLevels.map(({ symbol }) => (
                                    <MenuItem key={symbol} value={symbol}>
                                      <ListItemText primary={symbol} />
                                    </MenuItem>
                                  ))}
                                </Select>
                                {option.cityDemandLevelSymbol === "" && (
                                  <FormHelperText error={true}>
                                    <p style={{ whiteSpace: "nowrap" }}>
                                      {" "}
                                      Demand Level Not Selected
                                    </p>
                                  </FormHelperText>
                                )}
                              </DmdLevel>
                            ) : (
                              <DmdLevel>
                                <Select
                                  input={<Input />}
                                  disabled
                                  style={{ cursor: "not-allowed" }}
                                />
                              </DmdLevel>
                            )}
                        </Box>
                      </td>
                    </tr>
                </tbody>
              </TableNew>
            </TContainer>
            <Box ref={scrollRef} />
          </Box>
          <Box className="footer">
            <Button
              variant="contained"
              onClick={onSaveClick}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Fade>
    </PreciumModal>
  );
}
