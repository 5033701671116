import React, { useState, useEffect, memo, useCallback, useMemo } from "react";
import {
  useAuth,
  PrimaryButton,
  ErrorPage,
  NoAccess,
  LoadingPage,
} from "../../sdk";
import DialogContentText from "@mui/material/DialogContentText";
import { V2_ENABLED } from "../../sdk";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Autocomplete, FormGroup, FormHelperText } from "@mui/material";
import { createFilterOptions } from "@mui/material";
import { MenuItem, ListItemText, Checkbox } from "@mui/material";
import { styled } from "@mui/system";
import { styled as stl } from "@mui/material/styles";
import { DeleteSweep, Warning } from "@mui/icons-material";
import { Clear } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  AddressField,
  TextField,
  TextFieldSmall,
  Select,
  SelectContainer,
  ContactDetails,
  PageContainer,
  ContactHeader,
  FormWrapper,
  ButtonContainer,
  SelectDiv,
  HotelDetails,
  AddContact,
  InputLabelPDF as InputLabel,
  RemoveButtonPDF as RemoveButton,
  DataRangeContainer,
  InfoHeader,
  Gradient,
  Range,
  RangeFields,
  RangeText,
  NetworkPrompt,
  ChooseAppContainer,
} from "./Styles";
import { Info } from "@mui/icons-material";
import { Snackbar, SnackbarContent } from "@mui/material";
import {
  Grid,
  FormControl,
  Select as SelectBox,
  MenuItem as MenuItemBox,
  InputLabel as Input,
  TextField as TF,
  Box,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  DialogActions,
  Button,
} from "@mui/material";
import { useWarning } from "../../Provider/context";
import { weekdays } from "moment";
import { LoadingButton } from "@mui/lab";
import AvailableChannels from "./AvailableChannels";

const StyledContainer = styled(PageContainer)(({ theme }) => ({
  ".dropdownStyle": {
    borderRadius: 4,
    font: "normal normal normal 16px/20px Roboto",
    color: "#110641",
  },
  ".checkbox": {
    "&.Mui-checked": {
      color: "#306FBC",
    },
    "&:not(.Mui-checked)": {
      color: "#306FBC",
    },
  },
  ".checkboxContainer": {
    marginLeft: "25px",
    marginRight: "0px",
  },
}));

export const SelectBoxNew = styled(SelectBox)``;
export const CompetitorsTextField = styled(TF)`
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height */
    font-family: "Roboto";

    color: #000000;
    letter-spacing: 0px;
    width: 230px;
    min-width: 70px;
    color: #000000;
  }
  input {
    font-family: Roboto;
    letter-spacing: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;

    color: #110641;
    text-align: left;
    background: #ffffff;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: #110641 !important;
  }
  MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: #000000 !important;
    color: black !important;
  }
  &&&.muiinputbase-input.mui-disabled {
    -webkit-text-fill-color: #000000 !important;
  }
`;

export const AddressFieldNew = styled(CompetitorsTextField)`
  &.MuiFormControl-root {
    width: 100%;
  }
`;
export const DeleteIcon = styled(DeleteSweep)`
  color: #306fbc;
  cursor: pointer;
`;
export const Container = styled(Box)`
  background: #ffffff;
  box-shadow: 1px 4px 10px rgba(48, 111, 188, 0.25);
  border-radius: 4px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  margin-top: auto;
  margin-bottom: auto;
`;
export const ContainerNew = styled(Box)`
  background: #ffffff;
  // box-shadow: 1px 4px 10px rgba(48, 111, 188, 0.25);
  border-radius: 4px;
  // width: 44px;
  height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0px;
  margin-top: auto;
  margin-bottom: auto;
`;
const Text = styled(Box)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  color: #000000;
`;

const InfoIconBox = styled(InfoOutlined)`
  color: #306fbc;
  margin-left: 8px;
`;
const Line = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 0px;
`;
const ContainerBox = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 40px;
`;

const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    width: 258px;
    height: 48px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    background: #ffffff;
    border: 1px solid #d2d9d7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    text-align: center;
    color: #3e4644;
    background-color: white;
  }
  ,
  & .MuiTooltip-arrow {
    color: white;
  }
`;

export const TextOnlyTooltipPopover = styled(Tooltip)(() => ({
  ".tooltip": {
    font: "normal normal normal 12px/16px Roboto",
    marginLeft: 0,
    minWidth: "80px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",

    padding: "4px",
    background: " #ffffff 0% 0% no-repeat padding-box",
    border: "1px solid #A0A0A0",
    color: "#000000",
  },
  ".arrow": {
    "&:before": {
      border: "1px solid #A0A0A0",
    },
    color: "#ffffff",
  },
}));

const Days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const initialValues = {
  name: "",
  expediaName: "",
  otaName: "",
  ratepingName: "",
  address: "",
  city: "",
  rooms: null,
  brandWebsite: "none",
  brandWebsiteHotelCode: null,
  fileTypeID: null,
  reportTimeline: null,
  bookingPeriod: "",
  version: "",
  competitorPricingFileTypeID: "",
  hotelReferenceId: "",
  rateMetricHotelCode: "",
  sources: [],
  isPreciumEnabled: true,
  isChannelManagerEnabled: false,
  propertyIdHotelCode: "",
  channelManagerName: null,
  channelMangerId: "",
  travelClickName: "",
  rateGainName: "",
  managedBy: "",
  monthlyRateShopCredits: "",
  chain: "",
  createdAt: null,
  createdBy: null,
  emailList: null,
  id: null,
  isClone: false,
  isDeactivated: false,
  managedByName: "",
  organizationId: "",
  organizationName: "",
  timezone: "",
  weekdayMinPrice: null,
  weekendMinPrice: null,
  priceLimit: false,
  locationUrl: "",
  canViewUpcomingEvents: false,
  RSCurrency: "INR",
  sendEmailFrom: "",
};
const emailOptions = ["kamal@redskyhospitality.com", "sales@precium.in"];
var initialMidWeekValues = [];
const EMPTY_DETAIL = {
  name: "",
  postheld: "",
  email: "",
  phoneNumber: "",
  isSendEmail: false,
  invalidName: false,
  invalidPost: false,
  invalidEmail: false,
  invalidPhone: false,
};
const EMPTY_RANGE = {
  minValue: "",
  minColor: "#FF0000",
  medValue: "",
  medColor: "#FFFF00",
  maxValue: "",
  maxColor: "#00FF00",
};

const reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const rePhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
const reName = /^[a-zA-Z ]*$/;
const rePost = /^[a-zA-Z][a-zA-Z0-9 ]*$/;
const PrimaryDetailForm = memo(
  ({
    actualFileType,
    competitorFileType,
    handleNavbarRefresh,
    contactDetails,
    setContactDetails,
    onRemoveContactDetail,
    sendContact,
    setSendContact,
    checkHeight,
    onCheckContactDetail,
    rateShopAppEnabled,
    setRateShopAppEnabled,
  }) => {
    const [weeks, setWeeks] = useState([]);
    const {
      token,
      authFetch,
      isAdmin,
      permission,
      setVersion,
      setRateShopFeature,
      getHotelDetails,
      currentHotel,
    } = useAuth();

    const [hotelId, setHotelId] = useState(() => {
      let { hotelId } = useParams();
      return hotelId;
    });
    const [id, setId] = useState();
    const history = useHistory();
    const [WeekDays, setWeekDays] = useState(initialMidWeekValues);
    const [values, setValue] = useState(initialValues);
    const [fileType, setFileType] = useState([
      "competitor_file",
      "forecast_file",
      "market_segment_BOB_file",
    ]);

    const [allSources, setAllSources] = useState([]);
    const [brandWebsites, setBrandWebsites] = useState([]);
    const [channelManagers, setChannelManagers] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [selectedSources, setSelectedSource] = useState([]);
    const [brandWebsiteField, setBrandWebsiteField] = useState(false);
    const [mailFile, setMailFile] = useState();
    const [colorRanges, setColorRanges] = useState(EMPTY_RANGE);
    const location = useLocation();
    const [organizations, setOrganizations] = useState([]);
    const [managingOrganizations, setManagingOrganizations] = useState([]);
    const Standalone = { label: "Standalone", value: "Standalone" };
    const filter = createFilterOptions();
    const [open, setOpen] = React.useState(false);
    const [enableButton, setEnableButton] = useState(true);
    const [sendPace, setSendPace] = useState(false);
    const [isSaved, setIsSaved] = useState(true); // it will check whether data save or not , in the starting we are assuming that data is previous data already saved , if any changes take place in any field that time isSave will change into false.
    const [isOpenDialog, setIsOpenDialog] = useState(false); // if the data is not saved that time it used to open a dialog box.
    const [dialogSave, setDialogSave] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openWarning, setOpenWarning] = useState(false)
    const [isLimitPrice, setLimitPrice] = useState(false);
    const { formEdit, setformEdit, handleRedirect } = useWarning();
    const emailPlaceholder = V2_ENABLED
      ? "@em8172.parse.remotestate.com"
      : "@parse.precium.in";
    const [
      competitorPricingDialog,
      setCompetitorPricingDialog,
    ] = React.useState(false);
    const [validEmail, setValidEmail] = useState(true);

    // states to handle snackbar message
    const [Error, setError] = useState(false);
    const [networkMsg, setnetworkMsg] = useState(null);
    const [uploadNetworkMsg, setUploadNetworkMsg] = useState({
      open: false,
      color: "",
      msg: "",
    });

    const handleClickOpen = () => {
      setCompetitorPricingDialog(true);
    };

    const handleClose = () => {
      setCompetitorPricingDialog(false);
    };

    useEffect(() => {
      setId(hotelId);
    }, [hotelId]);

    useEffect(() => {
      if (values.competitorPricingFileTypeID === 8 && !rateShopAppEnabled) {
        setValue((state) => ({
          ...state,
          competitorPricingFileTypeID: 1,
        }));
      }
    }, [values.competitorPricingFileTypeID, rateShopAppEnabled]);

    const handleColorRange = (key, value) => {
      setIsSaved(false);
      setSendPace(true);
      setColorRanges({
        ...colorRanges,
        [key]: value,
      });
    };

    const defaultOrgProps = {
      options: [
        ...organizations.map((org) => {
          return {
            label: org.name,
            value: org.id,
          };
        }),
        Standalone,
      ],
      getOptionLabel: (option) => {
        if (typeof option === "string") {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.label;
      },
    };
    const optionsToShow = useMemo(() => {
      if (!rateShopAppEnabled) {
        return competitorFileType.filter((item) => item.id !== 8);
      }
      return competitorFileType.filter((item) => item.id === 8);
    }, [competitorFileType, rateShopAppEnabled]);

    const handleDeatils = (key, value, index) => {
      setIsSaved(false);
      setSendContact(true);
      setformEdit(true);
      let isValid = true;
      if (key === "name") {
        isValid =
          value.length > 0 && reName.test(String(value).toLowerCase().trim());
      } else if (key === "postheld") {
        isValid =
          value.length > 0 && rePost.test(String(value).toLowerCase().trim());
      } else if (key === "email") {
        isValid = reEmail.test(String(value).toLowerCase().trim());
      } else {
        isValid = rePhone.test(value.trim());
      }
      setContactDetails(
        contactDetails.map((detail, idx) => {
          if (idx === index) {
            if (key === "name") {
              return {
                ...detail,
                [key]: value,
                invalidName: !isValid,
              };
            } else if (key === "postheld") {
              return {
                ...detail,
                [key]: value,
                invalidPost: !isValid,
              };
            } else if (key === "email") {
              return {
                ...detail,
                [key]: value.trim(),
                invalidEmail: !isValid,
              };
            } else {
              return {
                ...detail,
                [key]: value.trim(),
                invalidPhone: !isValid,
              };
            }
          } else {
            return detail;
          }
        })
      );
    };

    console.log({ values });

    const handleMailFile = (key, value, index) => {
      setIsSaved(false);
      setSendFile(true);
      setformEdit(true);
      setMailFile(
        mailFile.map((detail, idx) => {
          if (idx === index) {
            if (key === "emailTo") {
              return {
                ...detail,
                [key]: value,
              };
            }
          } else {
            return detail;
          }
        })
      );
    };

    const addContactRow = (e) => {
      e.preventDefault();
      if (!contactDetails.every(isvalidContacts)) {
        return;
      }
      setContactDetails([...contactDetails, EMPTY_DETAIL]);
      setIsSaved(false);
    };
    const isvalidContacts = (cont) => {
      const isNameValid =
        cont.name.length > 0 &&
        reName.test(String(cont.name).trim().toLowerCase());
      const isPostValid =
        cont.postheld.length > 0 &&
        rePost.test(String(cont.postheld).trim().toLowerCase());
      const isEmailValid = reEmail.test(
        String(cont.email).trim().toLowerCase()
      );
      const isPhoneValid = rePhone.test(cont.phoneNumber.trim());
      return isNameValid && isPostValid && isEmailValid && isPhoneValid;
    };
    const isEmpty = (data) => {
      return (data) =>
        data.name === "" &&
        data.postheld === "" &&
        data.phoneNumber === "" &&
        data.email === "";
    };

    const handleCloseStatus = () => {
      setOpen(false);
    };

    const handleCloseUploadStatus = useCallback(() => {
      setUploadNetworkMsg({ ...uploadNetworkMsg, open: false });
    }, [uploadNetworkMsg]);

    const isValid = (data, mw) => {
      if (!data?.isPreciumEnabled) {
        return Boolean(
          data?.name &&
            data?.chain &&
            data?.managedBy &&
            data?.rateMetricHotelCode &&
            selectedSources.length > 0 &&
            data.monthlyRateShopCredits &&
            (brandWebsiteField
              ? Boolean(
                  data.brandWebsite !== "none" && data.brandWebsiteHotelCode
                )
              : true)
        );
      }
      if (
        !(
          data.organizationId &&
          data.name &&
          data.rooms &&
          data.reportTimeline &&
          data.bookingPeriod &&
          data.version &&
          data.competitorPricingFileTypeID &&
          data.fileTypeID &&
          data.hotelReferenceId &&
          data.city &&
          data.weekdayMinPrice &&
          data.weekendMinPrice &&
          data.chain &&
          data.managedBy &&
          (brandWebsiteField
            ? Boolean(
                data.brandWebsite !== "none" && data.brandWebsiteHotelCode
              )
            : true)
        )
      ) {
        return false;
      }
      if (data?.isChannelManagerEnabled) {
        if (!Boolean(data.propertyIdHotelCode && data.channelMangerId)) {
          return false;
        }
      }

      switch (data.competitorPricingFileTypeID) {
        case 1:
          return Boolean(
            rateShopAppEnabled
              ? Boolean(
                  data.rateMetricHotelCode &&
                    data.monthlyRateShopCredits &&
                    (data?.brandWebsite === "simplotel"
                      ? data?.brandWebsiteHotelCode
                      : true) &&
                    selectedSources.length > 0
                )
              : true
          );
        case 2:
          return Boolean(
            rateShopAppEnabled
              ? Boolean(
                  data.rateMetricHotelCode &&
                    data.monthlyRateShopCredits &&
                    (data?.brandWebsite === "simplotel"
                      ? data?.brandWebsiteHotelCode
                      : true) &&
                    selectedSources.length > 0
                )
              : true
          );
        case 3:
          return (
            data.expediaName &&
            data.ratepingName &&
            (rateShopAppEnabled
              ? Boolean(
                  data.rateMetricHotelCode &&
                    data.monthlyRateShopCredits &&
                    (data?.brandWebsite === "simplotel"
                      ? data?.brandWebsiteHotelCode
                      : true) &&
                    selectedSources.length > 0
                )
              : true)
          );
        case 4:
          return Boolean(
            rateShopAppEnabled
              ? Boolean(
                  data.rateMetricHotelCode &&
                    data.monthlyRateShopCredits &&
                    (data?.brandWebsite === "simplotel"
                      ? data?.brandWebsiteHotelCode
                      : true) &&
                    selectedSources.length > 0
                )
              : true
          );
        case 5:
          return Boolean(
            rateShopAppEnabled
              ? Boolean(
                  data.rateMetricHotelCode &&
                    data.monthlyRateShopCredits &&
                    (data?.brandWebsite === "simplotel"
                      ? data?.brandWebsiteHotelCode
                      : true) &&
                    selectedSources.length > 0
                )
              : true
          );
        case 6:
          return Boolean(
            rateShopAppEnabled
              ? Boolean(
                  data.rateMetricHotelCode &&
                    data.monthlyRateShopCredits &&
                    (data?.brandWebsite === "simplotel"
                      ? data?.brandWebsiteHotelCode
                      : true) &&
                    selectedSources.length > 0
                )
              : true
          );

        case 7:
          return Boolean(
            rateShopAppEnabled
              ? Boolean(
                  data.rateMetricHotelCode &&
                    data.monthlyRateShopCredits &&
                    (data?.brandWebsite === "simplotel"
                      ? data?.brandWebsiteHotelCode
                      : true) &&
                    selectedSources.length > 0
                )
              : true
          );
        case 8:
          return Boolean(
            rateShopAppEnabled
              ? Boolean(
                  data.rateMetricHotelCode &&
                    data.monthlyRateShopCredits &&
                    (data?.brandWebsite === "simplotel"
                      ? data?.brandWebsiteHotelCode
                      : true) &&
                    selectedSources.length > 0
                )
              : true
          );

        default:
          break;
      }
    };

    useEffect(() => {
      if (values.version === "v1") {
        setVersion("v1");
      } else if (values.version === "v2") {
        setVersion("v2");
      }
    }, [values, values.version, setVersion]);

    useEffect(() => {
      if (token) {
        getOrganization();
        getManagingOrganization();
      }
    }, [token]);

    //api to get sources name
    useEffect(() => {
      if (token) {
        getSources();
      } else {
        return;
      }
    }, [token]);

    async function getSources() {
      const { get } = await authFetch({
        path: `/sources`,
      });
      const { data, error } = await get();
      if (data) {
        setAllSources(
          data
            ?.filter((source) => !source?.isBrandWebsite)
            ?.sort((a, b) => a.sequence - b.sequence)
        );
        setBrandWebsites(data?.filter((source) => source?.isBrandWebsite));
      } else {
        console.log(error);
      }
    }

    // const getChannelManagers = useCallback(async () => {
    //   try {
    //     const { get } = await authFetch({
    //       path: `/hotel/${hotelId}/all-channel-manager`,
    //     });
    //     const { data, error } = await get();
    //     if (data) {
    //       setChannelManagers(data);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }, [authFetch, hotelId]);

    const getCurrency = useCallback(async () => {
      try {
        const { get } = await authFetch({
          path: `/rate-shop/currency`,
        });
        const { data } = await get();
        if (data) {
          setCurrencyList(data);
        }
      } catch (error) {
        console.log(error);
      }
    }, [authFetch]);

    useEffect(() => {
      if (rateShopAppEnabled) {
        getCurrency();
      }
    }, [getCurrency, rateShopAppEnabled]);

    console.log({ currencyList });

    // useEffect(() => {
    //   getChannelManagers();
    // }, [getChannelManagers]);

    const handleChannelChange = (event) => {
      setformEdit(true);

      if (event.target.value.includes("selectAll")) {
        if (allSources.length === selectedSources.length) {
          setSelectedSource([]);
        } else {
          const selectedIds = allSources.map((source) => {
            return {
              id: source?.id,
              name: source?.name,
              sourceId: source?.sourceId,
            };
          });
          setSelectedSource(selectedIds);
        }
      } else {
        const selectedIds = event.target.value.map((value) => {
          const source = allSources.find((source) => source.id === value);
          return {
            id: source?.id,
            name: source?.name,
            sourceId: source?.sourceId,
          };
        });
        setSelectedSource(selectedIds);
      }

      // const hasBrandWebsiteCode = selectedIds.some(
      //   (source) => source.sourceId === 8
      // );
      // if (hasBrandWebsiteCode) {
      //   setBrandWebsiteField(true);
      // } else {
      //   setBrandWebsiteField(false);
      //   setValue({
      //     ...values,
      //     brandWebsite: "none",
      //     brandWebsiteHotelCode: null,
      //   });
      // }
      // setIsSaved(false);
    };

    useEffect(() => {
      if (token && hotelId) {
        async function ColorRangeSetup() {
          const { get } = await authFetch({
            path: `/hotel/${hotelId}/pace-color-range`,
          });
          const { data, error } = await get();
          if (data) {
            setColorRanges(data);
          } else {
            setColorRanges([EMPTY_RANGE]);
            console.log(error);
          }
        }
        ColorRangeSetup();
      }
    }, [token, hotelId]);

    async function MailFileType() {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/file-upload-email`,
      });
      const { data, error } = await get();
      if (data) {
        setMailFile(data);
      } else {
        console.log(error);
      }
    }

    const [hotelDetailsEdit, setHotelDetailsEdit] = useState(null);
    const [hotelDetailsView, setHotelDetailsView] = useState(null);
    const [createChain, setCreateChain] = useState(null);
    const [openChannelDialog, setopenChannelDialog] = useState(false);

    useEffect(() => {
      if (token && hotelId) {
        MailFileType();
      }
    }, [token, hotelId]);

    useEffect(() => {
      if (token && hotelId && !!allSources.length) {
        AllDetailsSetup();
      }
      for (let key in permission) {
        if (permission.hasOwnProperty(key)) {
          if (permission[key].name === "hotelDetailsView") {
            setHotelDetailsView(permission[key]);
          }
          if (permission[key].name === "hotelDetailsEdit") {
            setHotelDetailsEdit(permission[key]);
          }
          if (permission[key].name === "createChain") {
            setCreateChain(permission[key]);
          }
        }
      }
    }, [token, hotelId, allSources, currentHotel, permission]);

    async function AllDetailsSetup() {
      // const { get } = await authFetch({
      //   path: `/hotel/${hotelId}`,
      // });
      // const { data, error } = await get();
      if (currentHotel) {
        const {
          name,
          expediaName,
          otaName,
          ratepingName,
          address,
          city,
          brandWebsite,
          brandWebsiteHotelCode,
          chain,
          // baseMinPrice,
          rooms,
          midWeek,
          fileTypeID,
          competitorPricingFileTypeID,
          reportTimeline,
          bookingPeriod,
          version,
          managedBy,
          hotelReferenceId,
          rateMetricHotelCode,
          isPreciumEnabled,
          isChannelManagerEnabled,
          propertyIdHotelCode,
          channelManagerName,
          channelMangerId,
          sources,
          createdAt,
          createdBy,
          rateGainName,
          emailList,
          id,
          isClone,
          isDeactivated,
          managedByName,
          organizationId,
          organizationName,
          timezone,
          weekdayMinPrice,
          weekendMinPrice,
          priceLimit,
          travelClickName,
          monthlyRateShopCredits,
          rateShopEnabled,
          locationUrl,
          canViewUpcomingEvents,
          RSCurrency,
          sendEmailFrom,
          channelManagerPropertyID,
        } = currentHotel;
        setValue({
          name,
          expediaName,
          ratepingName,
          otaName,
          address,
          city,
          brandWebsite,
          brandWebsiteHotelCode,
          chain: organizationName,
          rooms,
          fileTypeID,
          competitorPricingFileTypeID,
          reportTimeline,
          bookingPeriod,
          version,
          managedBy,
          hotelReferenceId,
          isPreciumEnabled,
          isChannelManagerEnabled,
          propertyIdHotelCode,
          channelManagerName,
          channelMangerId,
          createdAt,
          createdBy,
          emailList,
          id,
          isClone,
          isDeactivated,
          managedByName,
          organizationId,
          organizationName,
          timezone,
          weekdayMinPrice,
          canViewUpcomingEvents,
          weekendMinPrice,
          priceLimit,
          travelClickName,
          rateGainName,
          rateMetricHotelCode,
          monthlyRateShopCredits,
          locationUrl,
          RSCurrency,
          sendEmailFrom,
          channelManagerPropertyID
        });
        setRateShopAppEnabled(rateShopEnabled);

        const correspondingId = allSources.find((item) => item.sourceId === 8)
          ?.id;
        const brandWebsiteField =
          correspondingId && sources.includes(correspondingId);

        setBrandWebsiteField(brandWebsiteField);

        // console.log(sources, allSources, brandWebsite, brandWebsiteHotelCode)
        // setSelectedSource(
        //   sources.map((id) => {
        //     return allSources.find((item) => item.id === id && !item?.isMobile);
        //   })
        // );

        setSelectedSource(
          allSources.filter((source) => sources.some((id) => id === source?.id))
        );
        setWeekDays(midWeek?.map((val) => Days[val]) ?? []);
      }
    }

    const handleChange = (key, value) => {
      setIsSaved(false);
      setformEdit(true);
      setEnableButton(true);
      if (key === "version" && value === "v1") {
        setVersion("v1");
      } else if (key === "version" && value === "v2") {
        setVersion("v2");
      }
      if (key === "organizationId" && !value) {
        setEnableButton(false);
      }
      if (key === "brandWebsite" && value !== "simplotel") {
        setValue((state) => ({
          ...state,
          [key]: value,
          brandWebsiteHotelCode: "",
        }));
      }

      if (key === "channelManagerName") {
        const channelMangerId = channelManagers?.find(
          (item) => item.name === value
        )?.id;
        setValue((state) => ({ ...state, [key]: value, channelMangerId }));
      } else {
        setValue((state) => ({ ...state, [key]: value }));
      }
    };

    const handleWeekChange = (event) => {
      setformEdit(true);
      setWeekDays(event.target.value);
      setIsSaved(false);
    };
    const ratePingExpediaEnabled =
      values.competitorPricingFileTypeID !== 8 || !rateShopAppEnabled;

    useEffect(() => {
      setWeeks(WeekDays.map((day) => Days.indexOf(day)));
    }, [WeekDays]);

    const handleCloseDialog = useCallback(() => {
      setIsOpenDialog(false);
    }, [isOpenDialog]);

    // handle Submit function worked on save button click
    const [sendFile, setSendFile] = useState(false);
    const [myError, setMyError] = useState(false);

    const handleSubmit = async (e) => {
      setIsLoading(true);
      setMyError(true);
      setformEdit(false);
      if (
        !contactDetails.every(isvalidContacts) &&
        !isEmpty(contactDetails[contactDetails.length - 1])
      ) {
        return;
      }

      if (
        values.priceLimit &&
        (values.weekendMinPrice > 7499 || values.weekdayMinPrice > 7499)
      ) {
        setnetworkMsg("Values cannot be greater than threshold");
        setOpen(true);
        setIsLoading(false);
        return;
      }
      if (!isValid(values, weeks)) {
        setnetworkMsg("Field Cannot be Blank");
        setOpen(true);
        setIsLoading(false);
        return;
      }
      const { rateMetricHotelCode, monthlyRateShopCredits, ...rest } = values;
      const selectedChannelIds = selectedSources.map((source) => source.id);
      const options = {
        method: hotelId ? "PUT" : "POST",
        body: rateShopAppEnabled
          ? JSON.stringify({
              ...values,
              midWeek: weeks,
              sources: selectedChannelIds,
              rateShopEnabled: rateShopAppEnabled,
            })
          : JSON.stringify({
              ...rest,
              midWeek: weeks,
              sources: selectedChannelIds,
              rateShopEnabled: rateShopAppEnabled,
            }),
        headers: {
          token,
        },
      };

      // PUT method not returning anything
      var response;
      if (hotelId) {
        try {
          const { put } = await authFetch({
            path: `/hotel/${hotelId}`,
          });

          const { error, response } = await put(
            rateShopAppEnabled
              ? {
                  ...values,
                  hotelReferenceId:
                    values.hotelReferenceId === null
                      ? null
                      : values.hotelReferenceId.trim().length === 0
                      ? " "
                      : values.hotelReferenceId.trim(),
                  midWeek: weeks,
                  sources: selectedChannelIds,
                  rateShopEnabled: rateShopAppEnabled,
                  competitorPricingFileTypeID: values.competitorPricingFileTypeID
                    ? values.competitorPricingFileTypeID
                    : null,
                }
              : {
                  ...rest,
                  hotelReferenceId:
                    values.hotelReferenceId === null
                      ? null
                      : values.hotelReferenceId.trim().length === 0
                      ? " "
                      : values.hotelReferenceId.trim(),
                  midWeek: weeks,
                  sources: selectedChannelIds,
                  rateShopEnabled: rateShopAppEnabled,
                }
          );
          console.log({ response });
          if (response.status === 200) {
            SetIdFunctions(hotelId);
            getHotelDetails(hotelId);
            setRateShopFeature(rateShopAppEnabled);
            setUploadNetworkMsg({
              ...uploadNetworkMsg,
              open: true,
              color: "green",
              msg: "Saved Successfully",
            });
          } else if (
            response.status === 400 &&
            response?.data?.messageToUser ===
              "Incorrect Hotel Location URL. It not contains lat. and long."
          ) {
            setUploadNetworkMsg({
              ...uploadNetworkMsg,
              open: true,
              color: "red",
              msg:
                "Incorrect Hotel Location URL. It does not contain latitude and longitude",
            });
          } else {
            setUploadNetworkMsg({
              ...uploadNetworkMsg,
              open: true,
              color: "red",
              msg: "Something went wrong, Please try again.",
            });
          }
          if (error) {
            setError(true);
          } else {
            setError(false);
          }
        } catch {
          setError(true);
        }
      } else {
        try {
          const { post } = await authFetch({
            path: `/hotel`,
          });

          const { data, error } = await post(
            rateShopAppEnabled
              ? {
                  ...values,
                  midWeek: weeks,
                  sources: selectedChannelIds,
                  rateShopEnabled: rateShopAppEnabled,
                }
              : {
                  ...rest,
                  midWeek: weeks,
                  sources: selectedChannelIds,
                  rateShopEnabled: rateShopAppEnabled,
                }
          );
          if (data) {
            response = data;
            setHotelId(data.id);
            getHotelDetails(data?.id);
            setRateShopFeature(rateShopAppEnabled);
            setUploadNetworkMsg({
              ...uploadNetworkMsg,
              open: true,
              color: "green",
              msg: "Hotel Added Successfully",
            });
            SetIdFunctions(data.id);
            setError(false);
          }
          if (error) {
            setError(true);
          }
        } catch (e) {
          setUploadNetworkMsg({
            ...uploadNetworkMsg,
            open: true,
            color: "red",
            msg: "Incorrect Hotel Details",
          });
          setOpen(true);
        }
      }
      if (id) {
        const { get } = await authFetch({
          path: `/hotel`,
        });
        const { data, error } = await get();
        if (data) {
          setError(false);
          localStorage.setItem("hotels", JSON.stringify(data));
          handleNavbarRefresh();
        } else {
          setnetworkMsg("Incorrect Hotel Details");
        }
      }
      async function SetAllContacts(id) {
        try {
          const { post } = await authFetch({
            path: `/hotel/${id}/contact/all`,
          });
          const { data } = await post(
            contactDetails.filter(isvalidContacts).map((d) => {
              return {
                ...d,
                name: d.name.trim(),
                email: d.email.trim(),
                postheld: d.postheld.trim(),
                phoneNumber: d.phoneNumber.trim(),
              };
            })
          );

          if (data.message === "Upsert contacts successfully") {
            setContactDetails(contactDetails);
            setError(false);
          } else {
            setnetworkMsg("Incorrect Contact Details");
            setOpen(true);
            setContactDetails([EMPTY_DETAIL]);
          }
        } catch (e) {
          setnetworkMsg("Incorrect Contact Details");
          setOpen(true);
        }
      }

      async function SetMailFileCategory(id) {
        const { post } = await authFetch({
          path: `/hotel/${id}/file-upload-email`,
        });
        const { data, error } = await post(
          mailFile.map((d) => {
            return {
              ...d,
              emailTo: d.emailTo,
              fileType: d.fileType,
            };
          })
        );
        if (data.statusCode === 200) {
          setMailFile(mailFile);
          setError(false);
        } else if (data.statusCode === 400) {
          setnetworkMsg("cannot have multiple entry of same file type");
          setOpen(true);
          setMailFile([{ emailTo: "", fileType: "" }]);
        } else if (data.statusCode === 500) {
          setnetworkMsg(
            "Unable to complete upsert process for file upload emails"
          );
          setOpen(true);
          setMailFile([{ emailTo: "", fileType: "" }]);
        }
        MailFileType();
      }

      async function SetAllPaceColors(id) {
        try {
          const { post } = await authFetch({
            path: `/hotel/${id}/pace-color-range`,
          });
          const { data, error } = await post({
            minValue: colorRanges.minValue,
            minColor: "#FF0000",
            medValue: colorRanges.medValue,
            medColor: "#FFFF00",
            maxValue: colorRanges.maxValue,
            maxColor: "#00FF00",
          });
          if (data) {
          } else {
            setnetworkMsg("Incorrect Color Values");
            setOpen(true);
          }
        } catch (e) {
          setnetworkMsg("Incorrect Color Values");
          setOpen(true);
        }
      }

      async function SetIdFunctions(id) {
        if (sendContact) {
          await SetAllContacts(id);
        }
        if (sendPace) {
          await SetAllPaceColors(id);
        }
        if (dialogSave) {
          await nextPage(id);
        }
        if (sendFile) {
          await SetMailFileCategory(id);
        }
        history.push(`/hotel/${id}/setup`);
        setIsSaved(true);
      }

      function nextPage(id) {
        if (id) {
          history.push(`/hotel/${id}/setup/competitors`);
        }
      }
      setIsLoading(false);
    };

    async function getOrganization() {
      setIsLoading(true);

      const { get } = await authFetch({
        path: `/organizations`,
      });
      const { data, error } = await get();
      if (data) {
        const chains = data.filter((chain) => {
          return chain.isStandalone === false;
        });
        setOrganizations(chains);
      }
      setIsLoading(false);
    }
    async function getManagingOrganization() {
      const { get } = await authFetch({
        path: `/all-managing-org`,
      });
      const { data, error } = await get();
      if (data) {
        setManagingOrganizations(data);
      } else {
        console.log(error);
      }
    }

    const handleOrgChange = async (value) => {
      setIsSaved(false);
      setEnableButton(false);
      setformEdit(true);
      handleChange("chain", value.label);
      handleChange("organizationName", value.label);
      if (typeof value === "object") {
        setEnableButton(true);
        if (value.label === Standalone.label) {
          handleChange("organizationId", parseInt(-1));
        } else {
          handleChange("organizationId", value?.value);
        }
      } else if (typeof value === "string") {
        if (value.includes("Add Chain: ")) {
          value = value.replace("Add Chain: ", "");
        }
        handleChange("chain", value);
        handleChange("organizationName", value);
        const { post } = await authFetch({
          path: `/create-chain`,
        });
        const { data } = await post({ name: value });
        if (data) {
          setOrganizations([...organizations, data]);
          handleChange("organizationId", parseInt(data.id));
          setEnableButton(true);
        }
      }
    };

    const renderChannels = useCallback(
      (selectedIds) => {
        const options = selectedIds.reduce((acc, curr) => {
          const value = allSources.find(({ id }) => curr === id);
          const label = value?.isMobile
            ? `${value?.name} (Mobile)`
            : `${value?.name} (Desktop)`;
          return [...acc, label];
        }, []);

        return options.join(",");
      },
      [allSources]
    );

    return !Error && hotelDetailsView ? (
      <StyledContainer>
        {networkMsg && (
          <NetworkPrompt>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={open}
              autoHideDuration={4000}
              onClose={handleCloseStatus}
            >
              <SnackbarContent
                style={{ backgroundColor: "#CA3433" }}
                message={networkMsg}
              />
            </Snackbar>
          </NetworkPrompt>
        )}
        {uploadNetworkMsg?.open && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={uploadNetworkMsg?.open}
            autoHideDuration={3000}
            onClose={handleCloseUploadStatus}
          >
            <SnackbarContent
              style={{ backgroundColor: uploadNetworkMsg?.color }}
              message={uploadNetworkMsg?.msg}
            />
          </Snackbar>
        )}

        {/* info dialog when competitor file typeid is changed */}
        <Dialog open={competitorPricingDialog} onClose={handleClose}>
          <DialogTitle>{"User Information"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              If you have updated Competitor Pricing File Type here, then make
              sure to update names on Competitor Setup page as well in order to
              Upload Competitor files properly.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained">
              Okay
            </Button>
          </DialogActions>
        </Dialog>
        {openChannelDialog && (
          <AvailableChannels
            open={openChannelDialog}
            handleClose={() => setopenChannelDialog(false)}
            values={values}
            selectedSources={selectedSources}
            allSources={allSources}
          />
        )}
        <FormWrapper id="a-form">
          <ChooseAppContainer>
            <InfoHeader>Type of App Required</InfoHeader>
            <FormGroup
              sx={{
                width: "100%",
              }}
            >
              <Stack
                width={"100%"}
                direction={"row"}
                p={2}
                spacing={{
                  xs: 2,
                  md: 15,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={hotelDetailsEdit ? false : true}
                      sx={{
                        color: "#163A90",
                        "&.Mui-checked": {
                          color: "#163A90",
                        },
                        "&.Mui-disabled": {
                          color: "#AAAAAA"
                        }
                      }}
                      checked={rateShopAppEnabled}
                      onChange={(e) => {
                        console.log(e.target.checked)
                        if (e.target.checked) {
                          setformEdit(true);
                          setRateShopAppEnabled(e.target.checked);
                          if (
                            e.target.checked === false &&
                            values.competitorPricingFileTypeID === 8
                          ) {
                            setValue((state) => ({
                              ...state,
                              competitorPricingFileTypeID: 1,
                            }));
                          } else {
                            setValue((state) => ({
                              ...state,
                              competitorPricingFileTypeID: 8,
                            }));
                          }
                        } else {
                          setOpenWarning(true);
                        }
                      }}
                    />
                  }
                  label="Rate Shopping App"
                />

                {/* warning dialog when RateShopApp disabled */}
                <Dialog open={openWarning} onClose={() => { setOpenWarning(false) }}>
                  <DialogContent>
                    <Stack direction={"row"} width={"100%"} justifyContent={"center"} alignItems={"center"}>
                      <Typography variant="h6">
                        Warning
                      </Typography>
                      <Warning color="error" />
                    </Stack>
                    <Typography textAlign={"center"}>
                      Note: If You disable the “Rate Shopping App”, all the Rate Shops scheduled for this property will also be deleted.
                    </Typography>
                    <Typography textAlign={"center"}>
                      Do you wish to proceed? Click “Yes” to confirm. CLick “No” to continue without changes.
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Stack direction={"row"} width={"100%"} justifyContent={"space-evenly"}>
                      <Button onClick={() => {
                        setOpenWarning(false);
                        setformEdit(true);
                        setRateShopAppEnabled(false);
                        if (
                          values.competitorPricingFileTypeID === 8
                        ) {
                          setValue((state) => ({
                            ...state,
                            competitorPricingFileTypeID: 1,
                          }));
                        }
                      }} variant="contained">
                        Yes
                      </Button>
                      <Button onClick={() => { setOpenWarning(false) }} variant="contained">
                        No
                      </Button>
                    </Stack>
                  </DialogActions>
                </Dialog>
                <FormControlLabel
                  sx={{
                    marginLeft: "0px",
                  }}
                  control={
                    <Checkbox
                      disabled={hotelDetailsEdit ? false : true}
                      checked={values?.isPreciumEnabled}
                      onChange={(e) => {
                        setformEdit(true);
                        handleChange("isPreciumEnabled", e.target.checked);
                      }}
                      sx={{
                        color: "#163A90",
                        "&.Mui-checked": {
                          color: "#163A90",
                        },
                        "&.Mui-disabled": {
                          color: "#AAAAAA"
                        }
                      }}
                    />
                  }
                  label="Precium App"
                />
                <FormControlLabel
                  sx={{
                    marginLeft: "0px",
                  }}
                  control={
                    <Checkbox
                      disabled={hotelDetailsEdit ? false : true}
                      checked={values?.isChannelManagerEnabled}
                      onChange={(e) => {
                        setformEdit(true);
                        handleChange(
                          "isChannelManagerEnabled",
                          e.target.checked
                        );
                      }}
                      sx={{
                        color: "#163A90",
                        "&.Mui-checked": {
                          color: "#163A90",
                        },
                        "&.Mui-disabled": {
                          color: "#AAAAAA"
                        }
                      }}
                    />
                  }
                  label="Channel Manager"
                />
                <FormControlLabel
                  sx={{
                    marginLeft: "0px",
                  }}
                  control={
                    <Checkbox
                      disabled={hotelDetailsEdit ? false : true}
                      checked={values?.canViewUpcomingEvents}
                      onChange={(e) => {
                        setformEdit(true);
                        handleChange("canViewUpcomingEvents", e.target.checked);
                      }}
                      sx={{
                        color: "#163A90",
                        "&.Mui-checked": {
                          color: "#163A90",
                        },
                        "&.Mui-disabled": {
                          color: "#AAAAAA"
                        }
                      }}
                    />
                  }
                  label="Upcoming Events"
                />
              </Stack>
              {!values?.isPreciumEnabled && !rateShopAppEnabled && (
                <Typography sx={{ color: "red" }}>
                  Please select atleast one type of app
                </Typography>
              )}
            </FormGroup>
          </ChooseAppContainer>

          <HotelDetails style={{ minHeight: checkHeight ? "540px" : "480px" }}>
            <InfoHeader>Hotel Details</InfoHeader>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridColumnGap: "40px",
                marginTop: "10px",
              }}
            >
              <CompetitorsTextField
                required
                disabled={hotelDetailsEdit ? false : true}
                error={myError && !values.name}
                label="Name"
                name="hotelname"
                value={values.name || ""}
                onChange={(e) => handleChange("name", e.target.value)}
                style={{
                  width: "100%",
                  gridColumn: "1 / span 2",
                }}
                InputProps={{ disableUnderline: true }}
              />
              <CompetitorsTextField
                required
                disabled={hotelDetailsEdit ? false : true}
                error={
                  myError && values?.isPreciumEnabled ? !values.rooms : false
                }
                label="Number of Rooms"
                type="number"
                name="rooms"
                value={values.rooms || ""}
                onChange={(e) =>
                  handleChange("rooms", parseInt(e.target.value))
                }
                style={{ width: "100%" }}
                InputProps={{ disableUnderline: true }}
              />

              {/* travel click end */}
            </div>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridColumnGap: "40px",
                marginTop: "18px",
              }}
            >
              <AddressFieldNew
                required
                disabled={hotelDetailsEdit ? false : true}
                label="Address"
                name="address"
                value={values.address || ""}
                onChange={(e) => handleChange("address", e.target.value)}
                InputProps={{ disableUnderline: true }}
                style={{
                  width: "100%",
                  gridColumn: "1 / span 2",
                }}
              />
              <SelectDiv style={{ marginTop: "0px" }}>
                <CompetitorsTextField
                  required
                  label={"City"}
                  name="city"
                  disabled={hotelDetailsEdit ? false : true}
                  error={
                    myError && values?.isPreciumEnabled ? !values.city : false
                  }
                  value={values.city || ""}
                  onChange={(e) => handleChange("city", e.target.value)}
                  style={{ width: "100%", color: "#110641" }}
                  InputProps={{ disableUnderline: true }}
                />
              </SelectDiv>
            </div>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridColumnGap: "40px",
                marginTop: "18px",
              }}
            >
              <SelectDiv>
                <FormControl variant="outlined" fullWidth>
                  <Input
                    required
                    id="test-select-label"
                    disabled={hotelDetailsEdit ? false : true}
                    error={
                      myError && values?.isPreciumEnabled
                        ? !values?.reportTimeline
                        : false
                    }
                  >
                    Report Timeline
                  </Input>
                  <SelectBox
                    value={values.reportTimeline || ""}
                    disabled={hotelDetailsEdit ? false : true}
                    onChange={(e) =>
                      handleChange("reportTimeline", parseInt(e.target.value))
                    }
                    classes={{
                      paper: "dropdownStyle",
                    }}
                    style={{
                      width: "100%",
                      color: "#110641",
                    }}
                    disableUnderline
                    required={true}
                    label="Report Timeline"
                    labelId="test-select-label"
                  >
                    <MenuItemBox
                      style={{
                        font: "normal normal normal 16px/20px Roboto",
                      }}
                      value={3}
                    >
                      3 Months
                    </MenuItemBox>
                    <MenuItemBox
                      style={{
                        font: "normal normal normal 16px/20px Roboto",
                      }}
                      value={6}
                    >
                      6 Months
                    </MenuItemBox>
                  </SelectBox>
                </FormControl>
              </SelectDiv>
              <SelectDiv>
                <FormControl variant="outlined" fullWidth>
                  <Input
                    required
                    id="test-select-label"
                    disabled={hotelDetailsEdit ? false : true}
                    error={
                      myError && values?.isPreciumEnabled
                        ? !values.bookingPeriod
                        : false
                    }
                  >
                    Budgeting Period
                  </Input>
                  <SelectBox
                    value={values.bookingPeriod || ""}
                    disabled={hotelDetailsEdit ? false : true}
                    onChange={(e) =>
                      handleChange("bookingPeriod", e.target.value)
                    }
                    classes={{
                      paper: "dropdownStyle",
                    }}
                    style={{
                      width: "100%",
                      color: "#110641",
                    }}
                    disableUnderline
                    required={true}
                    label="Budgeting Period"
                  >
                    <MenuItemBox
                      style={{
                        font: "normal normal normal 16px/20px Roboto",
                      }}
                      value={"Apr-Mar"}
                    >
                      Apr-Mar
                    </MenuItemBox>
                    <MenuItemBox
                      style={{
                        font: "normal normal normal 16px/20px Roboto",
                      }}
                      value={"Jan-Dec"}
                    >
                      Jan-Dec
                    </MenuItemBox>
                    <MenuItemBox
                      style={{
                        font: "normal normal normal 16px/20px Roboto",
                      }}
                      value={"Jul-Jun"}
                    >
                      Jul-Jun
                    </MenuItemBox>
                  </SelectBox>
                </FormControl>
              </SelectDiv>
              <SelectDiv>
                <FormControl variant="outlined" fullWidth>
                  <Input
                    id="test-select-label"
                    disabled={hotelDetailsEdit ? false : true}
                    error={
                      myError && values?.isPreciumEnabled
                        ? !values.competitorPricingFileTypeID
                        : false
                    }
                  >
                    Competitor price Check Report
                  </Input>
                  <SelectBox
                    label="Competitor price Check Report"
                    disabled={hotelDetailsEdit ? false : true}
                    value={values?.competitorPricingFileTypeID}
                    disableUnderline
                    onChange={(e) => {
                      handleChange(
                        "competitorPricingFileTypeID",
                        parseInt(e.target.value)
                      );
                      handleClickOpen();
                    }}
                    classes={{
                      paper: "dropdownStyle",
                    }}
                    style={{ flex: "1", color: "#110641" }}
                  >
                    {optionsToShow.map((file, fIndex) => (
                      <MenuItemBox
                        style={{
                          font: "normal normal normal 16px/20px Roboto",
                        }}
                        value={file.id}
                        key={fIndex}
                      >
                        {file.type} : {file.description}
                      </MenuItemBox>
                    ))}
                  </SelectBox>
                </FormControl>
              </SelectDiv>
            </div>

            <SelectContainer
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                gridColumnGap: "40px",
                marginTop: "18px",
              }}
            >
              <SelectDiv>
                <FormControl variant="outlined" fullWidth>
                  <Input
                    required
                    id="test-select-label"
                    disabled={hotelDetailsEdit ? false : true}
                  >
                    Weekday Setup
                  </Input>
                  <SelectBox
                    multiple
                    value={WeekDays || ""}
                    disabled={hotelDetailsEdit ? false : true}
                    onChange={handleWeekChange}
                    disableUnderline
                    label="Weekday Setup"
                    classes={{
                      paper: "dropdownStyle",
                    }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Placeholder</em>;
                      }

                      return selected
                        .map((day) => day.substring(0, 3))
                        .join(", ");
                    }}
                    MenuProps={{ getContentAnchorEl: null }}
                    style={{ flex: "1", color: "#110641" }}
                  >
                    {Days.map((name) => (
                      <MenuItemBox key={name} value={name}>
                        <ListItemText
                          classes={{
                            primary: "dropdownStyle",
                          }}
                          primary={name}
                        />
                        <Checkbox
                          checked={WeekDays.indexOf(name) > -1}
                          style={{
                            color: "rgb(48,111,188)",
                          }}
                        />
                      </MenuItemBox>
                    ))}
                  </SelectBox>
                </FormControl>
              </SelectDiv>
              <SelectDiv>
                <FormControl variant="outlined" fullWidth>
                  <Input
                    required
                    id="test-select-label"
                    disabled={hotelDetailsEdit ? false : true}
                    error={
                      myError && values?.isPreciumEnabled
                        ? !values.fileTypeID
                        : false
                    }
                  >
                    Property Management System
                  </Input>
                  <SelectBox
                    label="Property Management System"
                    disabled={hotelDetailsEdit ? false : true}
                    value={values.fileTypeID || ""}
                    disableUnderline
                    classes={{
                      paper: "dropdownStyle",
                    }}
                    onChange={(e) =>
                      handleChange("fileTypeID", parseInt(e.target.value))
                    }
                    style={{ flex: "1", color: "#110641" }}
                  >
                    {actualFileType.map((file, fIndex) => (
                      <MenuItemBox
                        style={{
                          font: "normal normal normal 16px/20px Roboto",
                        }}
                        value={file.id}
                        key={fIndex}
                      >
                        {file.type} : {file.description}
                      </MenuItemBox>
                    ))}
                  </SelectBox>
                </FormControl>
              </SelectDiv>
              {values.competitorPricingFileTypeID !== 5 &&
                values.competitorPricingFileTypeID !== 7 &&
                values.competitorPricingFileTypeID !== 4 &&
                values.competitorPricingFileTypeID !== 6 &&
                values.competitorPricingFileTypeID !== 2 &&
                ratePingExpediaEnabled && (
                  <CompetitorsTextField
                    required
                    disabled={hotelDetailsEdit ? false : true}
                    label={"Expedia Name"}
                    name="expediaName"
                    value={values.expediaName || ""}
                    error={
                      myError && values?.isPreciumEnabled
                        ? !values.expediaName
                        : false
                    }
                    onChange={(e) =>
                      handleChange("expediaName", e.target.value)
                    }
                    style={{ width: "100%" }}
                    InputProps={{ disableUnderline: true }}
                  />
                )}
              {values.competitorPricingFileTypeID === 5 && (
                <CompetitorsTextField
                  required
                  disabled={hotelDetailsEdit ? false : true}
                  error={
                    myError && values?.isPreciumEnabled
                      ? !values.otaName
                      : false
                  }
                  label={"OTA Insight Name"}
                  name="otaName"
                  value={values?.otaName || ""}
                  onChange={(e) => handleChange("otaName", e.target.value)}
                  style={{ width: "100%" }}
                  InputProps={{ disableUnderline: true }}
                />
              )}

              {values.competitorPricingFileTypeID === 7 && (
                <CompetitorsTextField
                  required
                  disabled={hotelDetailsEdit ? false : true}
                  error={
                    myError && values?.isPreciumEnabled
                      ? !values.rateGainName
                      : false
                  }
                  label={"Rategain Name"}
                  name="rateGainName"
                  value={values.rateGainName || ""}
                  onChange={(e) => handleChange("rateGainName", e.target.value)}
                  style={{ width: "100%" }}
                  InputProps={{ disableUnderline: true }}
                />
              )}

              {values.competitorPricingFileTypeID === 4 && (
                <CompetitorsTextField
                  required
                  disabled={hotelDetailsEdit ? false : true}
                  label={"Generic Name"}
                  name="name"
                  value={values.name || ""}
                  onChange={(e) => handleChange("name", e.target.value)}
                  style={{ width: "100%" }}
                  InputProps={{ disableUnderline: true }}
                />
              )}

              {values.competitorPricingFileTypeID !== 1 &&
                values.competitorPricingFileTypeID !== 4 &&
                values.competitorPricingFileTypeID !== 5 &&
                values.competitorPricingFileTypeID !== 7 &&
                values.competitorPricingFileTypeID !== 6 &&
                ratePingExpediaEnabled && (
                  <CompetitorsTextField
                    required
                    disabled={hotelDetailsEdit ? false : true}
                    label="Rateping Name"
                    name="ratepingName"
                    value={values.ratepingName || ""}
                    error={
                      myError &&
                      values?.isPreciumEnabled &&
                      values.competitorPricingFileTypeID !== 8
                        ? !values.ratepingName
                        : false
                    }
                    onChange={(e) =>
                      handleChange("ratepingName", e.target.value)
                    }
                    style={{ width: "100%" }}
                    InputProps={{ disableUnderline: true }}
                  />
                )}

              {/* travel click */}

              {values.competitorPricingFileTypeID === 6 && (
                <CompetitorsTextField
                  required
                  disabled={hotelDetailsEdit ? false : true}
                  label={"TravelClick"}
                  name="travelClickName"
                  value={values.travelClickName || ""}
                  error={
                    myError && values?.isPreciumEnabled
                      ? !values.travelClickName
                      : false
                  }
                  onChange={(e) =>
                    handleChange("travelClickName", e.target.value)
                  }
                  style={{ width: "100%" }}
                  InputProps={{ disableUnderline: true }}
                />
              )}
            </SelectContainer>
            <SelectContainer
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                width: "100%",
                gridColumnGap: "40px",
                marginTop: "18px",
              }}
            >
              <SelectDiv>
                <FormControl variant="outlined" fullWidth>
                  <Input
                    id="test-select-label"
                    disabled={hotelDetailsEdit ? false : true}
                    error={myError && !values.managedBy}
                  >
                    Organization
                  </Input>
                  <SelectBox
                    value={values.managedBy || ""}
                    disabled={hotelDetailsEdit ? false : true}
                    error={myError && !values.managedBy}
                    disableUnderline
                    classes={{
                      paper: "dropdownStyle",
                      ListItemText: "list",
                    }}
                    onChange={(e) =>
                      handleChange("managedBy", parseInt(e.target.value))
                    }
                    style={{
                      minWidth: "240px",
                      color: "#110641",
                    }}
                    label="Organization"
                  >
                    {managingOrganizations.map(
                      (managingOrganization, orgIndex) => (
                        <MenuItemBox
                          style={{
                            font: "normal normal normal 16px/20px Roboto",
                          }}
                          value={managingOrganization.id}
                          key={orgIndex}
                        >
                          {managingOrganization.name}
                        </MenuItemBox>
                      )
                    )}
                  </SelectBox>
                </FormControl>
              </SelectDiv>
              <SelectDiv
                style={{
                  marginTop: "0px",
                }}
              >
                {createChain ? (
                  <Autocomplete
                    {...defaultOrgProps}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    underlineStyle={{ display: "none" }}
                    disabled={hotelDetailsEdit ? false : true}
                    underlineShow={false}
                    error={myError && !values.chain}
                    autoComplete
                    value={{
                      label: values?.organizationName,
                      value: values?.organizationId,
                    }}
                    style={{
                      width: "100%",
                      color: "#110641",
                    }}
                    onChange={(event, newValue) => {
                      handleOrgChange(newValue);
                    }}
                    classes={{
                      paper: "dropdownStyle",
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (params.inputValue !== "") {
                        filtered.push(`Add Chain: ${params.inputValue}`);
                      }
                      return filtered;
                    }}
                    freeSolo
                    disableClearable
                    renderInput={(params) => {
                      params.inputProps.value = params.inputProps.value.replace(
                        "Add Chain: ",
                        ""
                      );

                      return (
                        <CompetitorsTextField
                          required
                          {...params}
                          label="Chain"
                          error={myError && !values.chain}
                          style={{
                            width: "100%",
                            color: "#110641",
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                        />
                      );
                    }}
                  />
                ) : (
                  <Autocomplete
                    {...defaultOrgProps}
                    underlineStyle={{ display: "none" }}
                    underlineShow={false}
                    disabled={hotelDetailsEdit ? false : true}
                    error={myError && !values.chain}
                    autoComplete
                    style={{
                      width: "100%",
                      color: "#110641",
                    }}
                    onChange={(event, newValue) => {
                      handleOrgChange(newValue);
                    }}
                    classes={{
                      paper: "dropdownStyle",
                    }}
                    freeSolo
                    disableClearable
                    renderInput={(params) => (
                      <CompetitorsTextField
                        required
                        {...params}
                        label="Chain"
                        error={myError && !values.chain}
                        style={{
                          width: "100%",
                          color: "#110641",
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                      />
                    )}
                  />
                )}
              </SelectDiv>
              <SelectDiv style={{ marginTop: "0px" }}>
                <CompetitorsTextField
                  required
                  disabled={hotelDetailsEdit ? false : true}
                  label="Hotel Reference Id"
                  name="hotelReferenceId,"
                  value={values?.hotelReferenceId || ""}
                  error={
                    myError && values?.isPreciumEnabled
                      ? !values.hotelReferenceId
                      : false
                  }
                  onChange={(e) =>
                    handleChange("hotelReferenceId", e.target.value)
                  }
                  style={{ width: "100%", color: "#110641" }}
                  InputProps={{ disableUnderline: true }}
                />
              </SelectDiv>
              <SelectDiv>
                <FormControl variant="outlined" fullWidth>
                  <Input
                    id="test-select-label"
                    disabled={hotelDetailsEdit ? false : true}
                    error={
                      myError && values?.isPreciumEnabled
                        ? !values.version
                        : false
                    }
                    required
                  >
                    Version
                  </Input>
                  <SelectBox
                    value={values.version || ""}
                    disabled={hotelDetailsEdit ? false : true}
                    onChange={(e) => handleChange("version", e.target.value)}
                    classes={{
                      paper: "dropdownStyle",
                    }}
                    style={{
                      width: "100%",
                      color: "#110641",
                    }}
                    disableUnderline
                    required={true}
                    label="Version"
                  >
                    <MenuItemBox
                      style={{
                        font: "normal normal normal 16px/20px Roboto",
                      }}
                      value={"v1"}
                    >
                      Precium 1.0
                    </MenuItemBox>
                    <MenuItemBox
                      style={{
                        font: "normal normal normal 16px/20px Roboto",
                      }}
                      value={"v2"}
                    >
                      Precium 2.0
                    </MenuItemBox>
                  </SelectBox>
                </FormControl>
              </SelectDiv>
            </SelectContainer>

            {/* next 
            section */}
            <ContainerBox
              sx={{
                marginTop: "18px",
              }}
            >
              {values?.canViewUpcomingEvents && (
                <SelectDiv>
                  <CompetitorsTextField
                    disabled={hotelDetailsEdit ? false : true}
                    label={`Location Map URL`}
                    name="locationUrl"
                    value={values.locationUrl || ""}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleChange("locationUrl", e.target.value)
                    }
                    InputProps={{ disableUnderline: true }}
                  />
                </SelectDiv>
              )}

              <SelectDiv>
                <CompetitorsTextField
                  required
                  disabled={hotelDetailsEdit ? false : true}
                  label={`Minimum Threshold Weekday Rate`}
                  type="number"
                  name="weekdayMinPrice"
                  value={values.weekdayMinPrice || ""}
                  error={
                    (values.priceLimit && values.weekdayMinPrice > 7499) ||
                    (myError && values?.isPreciumEnabled
                      ? !values.weekdayMinPrice
                      : false)
                  }
                  helperText={
                    values.priceLimit && values.weekdayMinPrice > 7499
                      ? "Value can't be greater than limit threshold"
                      : ""
                  }
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleChange("weekdayMinPrice", parseInt(e.target.value))
                  }
                  InputProps={{ disableUnderline: true }}
                />
              </SelectDiv>
              <SelectDiv>
                <CompetitorsTextField
                  required
                  disabled={hotelDetailsEdit ? false : true}
                  error={
                    (values.priceLimit && values.weekendMinPrice > 7499) ||
                    (myError && values?.isPreciumEnabled
                      ? !values.weekendMinPrice
                      : false)
                  }
                  helperText={
                    values.priceLimit && values.weekendMinPrice > 7499
                      ? "Value can't be greater than limit threshold"
                      : ""
                  }
                  label={`Minimum Threshold Weekend Rate`}
                  type="number"
                  name="weekendMinPrice"
                  value={values.weekendMinPrice || ""}
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleChange("weekendMinPrice", parseInt(e.target.value))
                  }
                  InputProps={{ disableUnderline: true }}
                />
              </SelectDiv>
              <ContainerNew>
                <Checkbox
                  required
                  disabled={hotelDetailsEdit ? false : true}
                  onChange={(e) => handleChange("priceLimit", e.target.checked)}
                  checked={values.priceLimit}
                  style={{
                    color: "#306fbc",
                  }}
                />
                <Text>Limit Threshold</Text>
                <TextOnlyTooltipPopover
                  disableFocusListener
                  disableTouchListener
                  placement="right-start"
                  arrow
                  interactive
                  title="Maximum price restricted to 7499 "
                >
                  <InfoIconBox />
                </TextOnlyTooltipPopover>
              </ContainerNew>
            </ContainerBox>

            <SelectContainer
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                width: "100%",
                gridColumnGap: "40px",
                marginTop: "18px",
              }}
            >
              <CompetitorsTextField
                select
                disabled={hotelDetailsEdit ? false : true}
                label={"Sender Email"}
                name="sendEmailFrom"
                value={values?.sendEmailFrom || ""}
                onChange={(e) => handleChange("sendEmailFrom", e.target.value)}
                sx={{ width: "100%", color: "#110641" }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        marginRight: "13px",
                        cursor: "pointer",
                        display: !values.sendEmailFrom && "none",
                      }}
                      onClick={() => {
                        setformEdit(true);
                        setValue((prev) => ({
                          ...prev,
                          sendEmailFrom: "",
                        }));
                      }}
                    >
                      {values?.sendEmailFrom !== "" && (
                        <Clear fontSize="small" />
                      )}
                    </InputAdornment>
                  ),
                }}
              >
                {emailOptions.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </CompetitorsTextField>

              {/* {values?.isChannelManagerEnabled ? (
                <SelectDiv>
                  <CompetitorsTextField
                    select
                    disabled={hotelDetailsEdit ? false : true}
                    label="Channel Managers"
                    name="channelManagerName"
                    value={values?.channelManagerName}
                    onChange={(e) =>
                      handleChange("channelManagerName", e.target.value)
                    }
                    error={myError && !values.channelManagerName}
                    sx={{ width: "100%", color: "#110641" }}
                    InputProps={{ disableUnderline: true }}
                  >
                    {channelManagers.map((manager) => (
                      <MenuItem key={manager?.id} value={manager?.name}>
                        {manager?.name}
                      </MenuItem>
                    ))}
                  </CompetitorsTextField>
                </SelectDiv>
              ) : null} */}
              {values?.isChannelManagerEnabled ? (
                <SelectDiv>
                  <CompetitorsTextField
                    required
                    type="number"
                    label={"Property Id"}
                    name="propertyIdHotelCode"
                    disabled={hotelDetailsEdit ? false : true}
                    error={myError && !values.channelManagerPropertyID}
                    value={values.channelManagerPropertyID || ""}
                    onChange={(e) =>
                      handleChange("propertyIdHotelCode", e.target.value)
                    }
                    style={{ width: "100%", color: "#110641" }}
                    InputProps={{ disableUnderline: true }}
                  />
                </SelectDiv>
              ) : null}
            </SelectContainer>
          </HotelDetails>

          {/* "RateShop Setup section"  */}
          {rateShopAppEnabled && (
            <HotelDetails style={{ minHeight: "0px", marginTop: "24px" }}>
              <InfoHeader>Rateshop Setup</InfoHeader>
              <SelectContainer
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax( 100px , 1fr))",
                  width: "100%",
                  gridColumnGap: "40px",
                  marginTop: "18px",
                }}
              >
                <SelectDiv
                  style={{ marginTop: "0px", display: "flex", gap: "20px" }}
                >
                  <CompetitorsTextField
                    required
                    disabled={hotelDetailsEdit ? false : true}
                    label="Hotel Rate Metric Code"
                    name="rateMetricHotelCode,"
                    value={values?.rateMetricHotelCode || ""}
                    error={myError && !values.rateMetricHotelCode}
                    onChange={(e) =>
                      handleChange(
                        "rateMetricHotelCode",
                        parseInt(e.target.value)
                      )
                    }
                    style={{ width: "60%", color: "#110641" }}
                    InputProps={{ disableUnderline: true }}
                  />
                  {values?.rateMetricHotelCode ? (
                    <LoadingButton onClick={() => setopenChannelDialog(true)}>
                      Get Channels
                    </LoadingButton>
                  ) : null}
                </SelectDiv>

                <SelectDiv>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel
                      id="multi-select-dropdown-label"
                      disabled={hotelDetailsEdit ? false : true}
                      error={myError && selectedSources.length < 1}
                    >
                      {" "}
                      Channels
                    </InputLabel>
                    <Select
                      disableUnderline
                      label="Channels"
                      disabled={hotelDetailsEdit ? false : true}
                      style={{
                        flex: "1",
                        color: "#110641",
                        font: "normal normal normal 20px Roboto",
                      }}
                      labelId="multi-select-dropdown-label"
                      id="multi-select-dropdown"
                      multiple
                      value={selectedSources.map((source) => source?.id) || ""}
                      error={myError && selectedSources.length < 1}
                      onChange={handleChannelChange}
                      input={<OutlinedInput label="Channels" />}
                      renderValue={(selected) => {
                        return renderChannels(selected);
                      }}
                    >
                      <MenuItem value="selectAll">
                        <Checkbox
                          checked={
                            allSources?.length === selectedSources.length
                          }
                          style={{
                            color: "rgb(48,111,188)",
                            marginLeft: "450px",
                          }}
                        />
                        <ListItemText
                          primary="Select All"
                          style={{
                            float: "left",
                            position: "absolute",
                          }}
                        />
                      </MenuItem>
                      {allSources.map((source) => (
                        <MenuItem key={source?.id} value={source?.id}>
                          <Checkbox
                            checked={selectedSources.some(
                              (c) => c?.id === source?.id
                            )}
                            style={{
                              color: "rgb(48,111,188)",
                              marginLeft: "450px",
                            }}
                          />
                          <ListItemText
                            primary={
                              source?.isMobile
                                ? `${source?.name} (Mobile)`
                                : `${source?.name} (Desktop)`
                            }
                            style={{
                              float: "left",
                              position: "absolute",
                            }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </SelectDiv>

                <SelectDiv>
                  <CompetitorsTextField
                    select
                    disabled={hotelDetailsEdit ? false : true}
                    label="Booking Engine"
                    name="brandWebsite"
                    value={
                      values?.brandWebsite !== "none"
                        ? values?.brandWebsite
                        : ""
                    }
                    onChange={(e) =>
                      handleChange("brandWebsite", e.target.value)
                    }
                    // error={myError &&  !(values.brandWebsite !== "none")}
                    sx={{ width: "100%", color: "#110641" }}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            marginRight: "13px",
                            cursor: "pointer",
                            display: !values.brandWebsite && "none",
                          }}
                          onClick={() => {
                            setformEdit(true);
                            setValue((prev) => ({
                              ...prev,
                              brandWebsite: "none",
                              brandWebsiteHotelCode: null,
                            }));
                          }}
                        >
                          {values?.brandWebsite !== "none" && (
                            <Clear fontSize="small" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  >
                    {brandWebsites.map((website) => (
                      <MenuItem
                        key={website?.id}
                        value={website?.name?.toLowerCase()}
                      >
                        {website?.name}
                      </MenuItem>
                    ))}
                  </CompetitorsTextField>
                </SelectDiv>
              </SelectContainer>{" "}
              <div
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gridColumnGap: "40px",
                  marginTop: "10px",
                }}
              >
                {values?.brandWebsite === "simplotel" && (
                  <SelectDiv style={{ marginTop: "14px" }}>
                    <CompetitorsTextField
                      required
                      disabled={hotelDetailsEdit ? false : true}
                      label="Booking Engine Hotel Code"
                      name="brandWebsiteHotelCode,"
                      type="number"
                      value={values?.brandWebsiteHotelCode}
                      onChange={(e) =>
                        handleChange("brandWebsiteHotelCode", e.target.value)
                      }
                      error={
                        myError && rateShopAppEnabled
                          ? !values.brandWebsiteHotelCode
                          : false
                      }
                      sx={{ width: "100%", color: "#110641" }}
                      InputProps={{ disableUnderline: true }}
                    />
                  </SelectDiv>
                )}
                <SelectDiv style={{ marginTop: "14px" }}>
                  <CompetitorsTextField
                    required
                    fullWidth
                    disabled={hotelDetailsEdit ? false : true}
                    label="Monthly RateShop Provided"
                    name="monthlyRateShopCredits,"
                    value={values?.monthlyRateShopCredits || ""}
                    error={myError && !values.monthlyRateShopCredits}
                    onChange={(e) =>
                      handleChange(
                        "monthlyRateShopCredits",
                        parseInt(e.target.value)
                      )
                    }
                    style={{ width: "100%", color: "#110641" }}
                    InputProps={{ disableUnderline: true }}
                  />
                </SelectDiv>
                <SelectDiv style={{ marginTop: "14px" }}>
                  <CompetitorsTextField
                    select
                    disabled={hotelDetailsEdit ? false : true}
                    label="Currency"
                    name="RSCurrency"
                    value={values?.RSCurrency}
                    onChange={(e) => handleChange("RSCurrency", e.target.value)}
                    // error={myError &&  !(values.brandWebsite !== "none")}
                    sx={{ width: "100%", color: "#110641" }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  >
                    {currencyList.map((item) => (
                      <MenuItem
                        key={item.currencycode}
                        value={item?.currencycode}
                        sx={{
                          textTransform: "uppercase",
                        }}
                      >
                        {`${item.currencyname?.toUpperCase()}(${
                          item?.currencycode
                        })`}
                      </MenuItem>
                    ))}
                  </CompetitorsTextField>
                </SelectDiv>
              </div>
            </HotelDetails>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: "24px",
              padding: "16px 16px 16px 24px",
              boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
            }}
          >
            <ContactHeader style={{ marginTop: "0px" }}>
              Contact Details
            </ContactHeader>
            {contactDetails &&
              contactDetails.map((detail, index) => (
                <ContactDetails key={index}>
                  <CompetitorsTextField
                    className={detail.invalidName ? "invalid" : ""}
                    disabled={hotelDetailsEdit ? false : true}
                    error={detail.invalidName}
                    name="name"
                    value={detail.name}
                    onChange={(e) =>
                      handleDeatils("name", e.target.value, index)
                    }
                    InputProps={{ disableUnderline: true }}
                    label="Name"
                    helperText={detail.invalidName ? "Invalid Entry" : ""}
                    style={{ flex: "1" }}
                  />
                  <CompetitorsTextField
                    className={detail.invalidPost ? "invalid" : ""}
                    error={detail.invalidPost}
                    disabled={hotelDetailsEdit ? false : true}
                    name="postheld"
                    value={detail.postheld}
                    onChange={(e) =>
                      handleDeatils("postheld", e.target.value, index)
                    }
                    InputProps={{ disableUnderline: true }}
                    label="Position"
                    helperText={detail.invalidPost ? "Invalid Entry" : ""}
                    style={{ flex: "1" }}
                  />
                  <CompetitorsTextField
                    className={detail.invalidEmail ? "invalid" : ""}
                    disabled={hotelDetailsEdit ? false : true}
                    error={detail.invalidEmail}
                    name="email"
                    value={detail.email}
                    onChange={(e) =>
                      handleDeatils("email", e.target.value, index)
                    }
                    InputProps={{ disableUnderline: true }}
                    label="Email"
                    helperText={detail.invalidEmail ? "Invalid Entry" : ""}
                    style={{ flex: "1" }}
                  />
                  <CompetitorsTextField
                    className={detail.invalidPhone ? "invalid" : ""}
                    disabled={hotelDetailsEdit ? false : true}
                    error={detail.invalidPhone}
                    name="tel"
                    value={detail.phoneNumber}
                    onChange={(e) =>
                      handleDeatils("phoneNumber", e.target.value, index)
                    }
                    InputProps={{ disableUnderline: true }}
                    label="Phone Number"
                    helperText={detail.invalidPhone ? "Invalid Entry" : ""}
                    style={{ flex: "1" }}
                  />
                  {hotelDetailsEdit && (
                    <Container>
                      <DeleteIcon
                        onClick={() => onRemoveContactDetail(index)}
                      />
                    </Container>
                  )}
                  {hotelDetailsEdit && (
                    <Container>
                      <StyledTooltip
                        title="If this checkbox is marked then this user will get the email if any file is uploaded by this hotel."
                        arrow
                        placement="top-start"
                      >
                        <FormControlLabel
                          control={
                            <div className="checkboxContainer">
                              <Checkbox
                                checked={detail.isSendEmail ?? false}
                                onClick={() => onCheckContactDetail(index)}
                                className="checkbox"
                                color="default"
                              />
                            </div>
                          }
                        />
                      </StyledTooltip>
                    </Container>
                  )}
                </ContactDetails>
              ))}
            {hotelDetailsEdit && (
              <AddContact
                style={{
                  display: "flex",
                  color: "#ffffff",
                  width: "fit-content",
                  backgroundColor: "#306fbc",
                  borderRadius: "5px",
                  alignItems: "center",
                }}
                onClick={addContactRow}
              >
                + Add Contact
              </AddContact>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: "24px",
              padding: "16px 16px 16px 24px",
              boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
            }}
          >
            <ContactHeader style={{ marginTop: "0px" }}>
              E-mail & File Type
            </ContactHeader>
            {mailFile &&
              mailFile.map((detail, index) => (
                <ContactDetails key={index}>
                  <CompetitorsTextField
                    name="emailTo"
                    disabled={hotelDetailsEdit ? false : true}
                    value={detail.emailTo.split("@")[0]}
                    onChange={(e) =>
                      handleMailFile(
                        "emailTo",
                        e.target.value + emailPlaceholder,
                        index
                      )
                    }
                    helperText={"You can use letters, numbers & periods"}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          {emailPlaceholder}
                        </InputAdornment>
                      ),
                    }}
                    label="User Name"
                    style={{ flex: "1" }}
                  />
                  <FormControl variant="outlined">
                    <Input disabled={hotelDetailsEdit ? false : true}>
                      Type of File
                    </Input>
                    <SelectBox
                      value={detail.fileType || ""}
                      disableUnderline
                      classes={{
                        paper: "dropdownStyle",
                        ListItemText: "list",
                      }}
                      onChange={(e) => {
                        setSendFile(true);
                        setMailFile(
                          mailFile.map((item, subIndex) => {
                            if (item === detail) {
                              return {
                                fileType: e.target.value,
                                emailTo: detail.emailTo,
                                label: detail.label,
                              };
                            } else {
                              return item;
                            }
                          })
                        );
                      }}
                      style={{
                        minWidth: "520px",
                        color: "#110641",
                      }}
                      label="Type of File"
                    >
                      {fileType.map((item, index) => (
                        <MenuItemBox
                          style={{
                            font: "normal normal normal 16px/20px Roboto",
                          }}
                          value={item}
                          key={index}
                        >
                          {item}
                        </MenuItemBox>
                      ))}
                    </SelectBox>
                  </FormControl>
                  {hotelDetailsEdit && (
                    <Container>
                      <DeleteIcon
                        onClick={() => {
                          setSendFile(true);
                          setMailFile(
                            mailFile.filter((item, subIndex) => item !== detail)
                          );
                        }}
                      />
                    </Container>
                  )}
                </ContactDetails>
              ))}
            {hotelDetailsEdit && (
              <AddContact
                style={{
                  display: "flex",
                  color: "#ffffff",
                  width: "fit-content",
                  backgroundColor: "#306fbc",
                  borderRadius: "5px",
                  alignItems: "center",
                }}
                onClick={() => {
                  setSendFile(true);
                  setMailFile([
                    ...(mailFile ?? []),
                    { emailTo: "", fileType: "" },
                  ]);
                }}
              >
                + Add File
              </AddContact>
            )}
          </div>

          <DataRangeContainer>
            <InfoHeader>Data Ranges - Pace</InfoHeader>
            <Gradient></Gradient>
            <Range>
              <RangeFields>
                <CompetitorsTextField
                  type="number"
                  name="minValue"
                  disabled={hotelDetailsEdit ? false : true}
                  value={colorRanges.minValue}
                  onChange={(e) => {
                    setformEdit(true);
                    handleColorRange("minValue", parseInt(e.target.value))
                  }}
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{ shrink: true }}
                  label="Low"
                />
              </RangeFields>
              <RangeFields>
                <CompetitorsTextField
                  type="number"
                  name="medValue"
                  value={colorRanges.medValue}
                  disabled={hotelDetailsEdit ? false : true}
                  onChange={(e) => {
                    setformEdit(true);
                    handleColorRange("medValue", parseInt(e.target.value))
                  }}
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{ shrink: true }}
                  label="Med"
                />
              </RangeFields>
              <RangeFields>
                <CompetitorsTextField
                  type="number"
                  name="maxValue"
                  value={colorRanges.maxValue}
                  disabled={hotelDetailsEdit ? false : true}
                  onChange={(e) => {
                    setformEdit(true);
                    handleColorRange("maxValue", parseInt(e.target.value))
                  }}
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{ shrink: true }}
                  label="High"
                />
              </RangeFields>
            </Range>
          </DataRangeContainer>
          <ButtonContainer>
            {hotelDetailsEdit &&
              (enableButton ? (
                <Stack direction={"row"} spacing={2}>
                  <PrimaryButton
                    next
                    onClick={handleSubmit}
                    type="submit"
                    sx={{
                      background: formEdit ? "#306FBC" : "#a8a4a4 !important",
                      boxShadow: "0px 4px 4px rgba(3, 4, 94, 0.2)",
                      borderRadius: "8px",
                      color: "#FFFFFF",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "18px",
                    }}
                    disabled={isLoading || !formEdit}
                    loading={isLoading}
                  >
                    Save
                  </PrimaryButton>
                  <PrimaryButton
                    onClick={() => {
                      handleRedirect(
                        `/hotel/${hotelId}/setup/${
                          rateShopAppEnabled ? "scheduling" : "competitors"
                        }`,
                        hotelId
                      );
                    }}
                    disabled={isLoading}
                    // loading={isLoading}
                  >
                    Next
                  </PrimaryButton>
                </Stack>
              ) : (
                <Stack direction={"row"} spacing={2}>
                  <PrimaryButton
                    next
                    disabled
                    style={{
                      cursor: "not-allowed",
                      opacity: "0.38",
                      background: "#306FBC",
                      boxShadow: "0px 4px 4px rgba(3, 4, 94, 0.2)",
                      borderRadius: "8px",
                      color: "#FFFFFF",
                    }}
                    type="submit"
                  >
                    Submit
                  </PrimaryButton>
                  <PrimaryButton
                    next
                    disabled
                    style={{
                      cursor: "not-allowed",
                      opacity: "0.38",
                      background: "#306FBC",
                      boxShadow: "0px 4px 4px rgba(3, 4, 94, 0.2)",
                      borderRadius: "8px",
                      color: "#FFFFFF",
                    }}
                    onClick={() => {
                      handleRedirect(
                        `/hotel/${hotelId}/setup/${
                          rateShopAppEnabled ? "scheduling" : "competitors"
                        }`,
                        hotelId
                      );
                    }}
                  >
                    Next
                  </PrimaryButton>
                </Stack>
              ))}
          </ButtonContainer>
        </FormWrapper>
      </StyledContainer>
    ) : !hotelDetailsView ? (
      !isLoading ? (
        <LoadingPage />
      ) : (
        <NoAccess />
      )
    ) : (
      <ErrorPage />
    );
  }
);

export default PrimaryDetailForm;
