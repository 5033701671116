import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../sdk";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useWarning } from "../../Provider/context";

export default function useChannelManager() {
  const { authFetch, currentHotel } = useAuth();
  const { hotelId } = useParams();
  const { formEdit, setformEdit, handleRedirect } = useWarning();

  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [propertyId, setPropertyId] = useState("");
  const [roomData, setRoomData] = useState(null);
  const [channelManagerData, setChannelManagerData] = useState({
    RoomRatePlanId: null,
    roomId: "",
    roomName: "",
    roomRateGroupCode: "",
    discount: 0,
    enablePropertyConnection: false,
    enablePushData: false,
  });
  const [snackbarMsg, setSnackBarMsg] = useState({
    open: false,
    msg: "",
    color: "",
  });
  const [arrData, setArrData] = useState(null);
  const [openPropertyIdModal, setOpenPropertyIdModal] = useState(false);

  useEffect(() => {
    setPropertyId(currentHotel?.channelManagerPropertyID);
  }, [currentHotel?.channelManagerPropertyID]);

  const getChannelManager = useCallback(async () => {
    try {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/get-room-rate-plan `,
      });
      const { data, response } = await get();
      if (data && response.status === 200) {
        setPropertyId(data[data?.length - 1]?.propertyId);
        setChannelManagerData({
          RoomRatePlanId: data[data?.length - 1]?.RoomRatePlanId,
          roomId: data[data?.length - 1]?.roomId,
          roomRateGroupCode: data[data?.length - 1]?.roomRateGroupCode,
          discount: data[data?.length - 1]?.discount,
          enablePropertyConnection:
            data[data?.length - 1]?.enablePropertyConnection,
          enablePushData: data[data?.length - 1]?.enablePushData,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, [authFetch, hotelId]);

  useEffect(() => {
    getChannelManager();
  }, [getChannelManager]);

  const getRoomMapping = useCallback(async () => {
    try {
      setIsLoading(true);
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/hotel-room-mapping`,
      });
      const { data, response } = await get();
      if (data && response.status === 200) {
        setRoomData(data?.mappings);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [authFetch, hotelId]);

  const handleSavePropertyId = useCallback(async () => {
    try {
      const requestBody = {
        propertyId: propertyId,
        roomId: channelManagerData.roomId,
        roomRateGroupCode: channelManagerData?.roomRateGroupCode,
        discount: channelManagerData?.discount,
        enablePropertyConnection: channelManagerData?.enablePropertyConnection,
        enablePushData: channelManagerData?.enablePushData,
      };
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/create-room-rate-plan`,
      });

      const { response, data } = await post(requestBody);
      if (response.status === 200) {
        getChannelManager();
        setSnackBarMsg({
          open: true,
          msg: "Saved Successfully",
          color: "#228B22",
        });
      } else {
        setSnackBarMsg({
          open: true,
          msg: data?.messageToUser || "Something went wrong",
          color: "#CA3433",
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, [
    authFetch,
    channelManagerData.discount,
    channelManagerData.enablePropertyConnection,
    channelManagerData.enablePushData,
    channelManagerData.roomId,
    channelManagerData.roomRateGroupCode,
    getChannelManager,
    hotelId,
    propertyId,
  ]);

  const handleSaveChannelmanager = useCallback(
    async (value) => {
      try {
        setformEdit(false);
        setIsLoading(true);
        const requestBody = {
          propertyId: propertyId,
          roomId: channelManagerData.roomId,
          roomRateGroupCode: channelManagerData?.roomRateGroupCode,
          discount: channelManagerData?.discount,
          enablePropertyConnection:
            value?.connectionStatus !== undefined
              ? value?.connectionStatus
              : channelManagerData?.enablePropertyConnection,
          enablePushData:
            value?.pushDataStatus !== undefined
              ? value?.pushDataStatus
              : channelManagerData?.pushDataStatus,
        };
        // const { put } = await authFetch({
        //   path: `/hotel/${hotelId}/update-room-rate-plan?ratePlanId=${channelManagerData?.RoomRatePlanId}`,
        // });
        const { post } = await authFetch({
          path: `/hotel/${hotelId}/create-room-rate-plan`,
        });

        const { response, data } = await post(requestBody);
        if (response.status === 200) {
          getChannelManager();
          setSnackBarMsg({
            open: true,
            msg: "Saved Successfully",
            color: "#228B22",
          });
        } else {
          setSnackBarMsg({
            open: true,
            msg: data?.messageToUser || "Something went wrong",
            color: "#CA3433",
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    },
    [
      authFetch,
      channelManagerData.discount,
      channelManagerData.enablePropertyConnection,
      channelManagerData.pushDataStatus,
      channelManagerData.roomId,
      channelManagerData.roomRateGroupCode,
      getChannelManager,
      hotelId,
      propertyId,
      setformEdit,
    ]
  );

  const handleClickConnectionButton = useCallback(async () => {
    try {
      const requestBody = {
        action: channelManagerData?.enablePropertyConnection
          ? "deactivate"
          : "activate",
      };
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/enable-disable-property-connection`,
      });
      const { response, data } = await post(requestBody);
      if (response.status === 200) {
        handleSaveChannelmanager({
          pushDataStatus: channelManagerData.enablePushData,
          connectionStatus: !channelManagerData.enablePropertyConnection,
        });
      } else {
        setSnackBarMsg({
          open: true,
          msg: data?.messageToUser || "Something went wrong",
          color: "#CA3433",
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, [
    authFetch,
    channelManagerData.enablePropertyConnection,
    channelManagerData.enablePushData,
    handleSaveChannelmanager,
    hotelId,
  ]);

  const handlePushDataClick = useCallback(async () => {
    try {
      const requestBody = {
        action: channelManagerData?.enablePushData ? "deactivate" : "activate",
      };
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/enable-disable-push-data-api`,
      });
      const { response, data } = await post(requestBody);
      if (response.status === 200) {
        handleSaveChannelmanager({
          pushDataStatus: !channelManagerData.enablePushData,
          connectionStatus: channelManagerData.enablePropertyConnection,
        });
      } else {
        setSnackBarMsg({
          open: true,
          msg: data?.messageToUser || "Something went wrong",
          color: "#CA3433",
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, [
    authFetch,
    channelManagerData.enablePropertyConnection,
    channelManagerData.enablePushData,
    handleSaveChannelmanager,
    hotelId,
  ]);

  const fetchArrData = useCallback(async () => {
    try {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/fetch-arr-staah`,
      });
      const { data, response } = await get();
      if (data && response.status === 200) {
        setArrData(data);
      }
    } catch (err) {
      console.log(err);
    }
  }, [authFetch, hotelId]);

  useEffect(() => {
    fetchArrData();
  }, [fetchArrData]);

  const handleSearchPropertyId = useCallback(() => {
    getRoomMapping();
    setOpenPropertyIdModal(true);
  }, [getRoomMapping]);

  const actionOnConnectionButtonClick = useCallback(() => {
    if (channelManagerData?.enablePropertyConnection) {
      setOpenWarningModal(true);
    } else {
      handleClickConnectionButton();
    }
  }, [
    channelManagerData.enablePropertyConnection,
    handleClickConnectionButton,
  ]);

  return {
    hotelId,
    handleRedirect,
    propertyId,
    setPropertyId,
    channelManagerData,
    setChannelManagerData,
    roomData,
    getRoomMapping,
    handleSavePropertyId,
    handleSaveChannelmanager,
    snackbarMsg,
    setSnackBarMsg,
    isLoading,
    handleClickConnectionButton,
    handlePushDataClick,
    arrData,
    openPropertyIdModal,
    setOpenPropertyIdModal,
    handleSearchPropertyId,
    openWarningModal,
    setOpenWarningModal,
    actionOnConnectionButtonClick,
    formEdit,
    setformEdit,
  };
}
