import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Snackbar,
  SnackbarContent,
  TextField as TF,
  FormControl as FC,
  Typography,
  debounce,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  ListItemText,
  Checkbox,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { maxWidth, styled } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";
import { IconButton } from "@mui/material";

import {
  ErrorPage,
  LoadingPage,
  NoAccess,
  PrimaryButton,
  useAuth,
} from "../sdk";
import UserListTable from "./components/UserListTable";
import { Stack } from "@mui/material";

const TextField = styled(TF)`
  input {
    font: normal normal normal 16px/20px Roboto;
    letter-spacing: 0px;
    color: #000000;
  }
`;
const FormControl = styled(FC)`
  input {
    font: normal normal normal 16px/20px Roboto;
    letter-spacing: 0px;
    color: #000000;
  }
`;

const PageTitle = styled(Box)`
  position: fixed;
  top: 13px;
  left: 80px;
  color: white;
  z-index: 999;
`;

const H = styled(Typography)`
  font: normal normal bold 24px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
`;
const Label = styled(Box)`
  font-family: Roboto;
  white-space: nowrap;
`;
const UserName = styled(Box)`
  font-family: Roboto;
`;
const Cross = styled(CloseRounded)`
  width: 20px;
  height: 20px;
  margin-left: 15px;
  :hover {
    cursor: pointer;
  }
`;

const TableContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 70px;
  padding-left: 76px;
  padding-right: 25px;
`;

export const HeadSection = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
`;

const reducerState = (prev, next)=>({...prev,...next})

export default function UserListPage() {
  const { permission, hotelSelect, token, authFetch } = useAuth();
  const [userList, setUserList] = useState();
  const [newUserList, setNewUserList] = useState(undefined);
  const { hotelId } = useParams();
  const [hotels, setHotels] = useState();
  const [Error, setError] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Nodata, setNodata] = useState(false);
  const [refeshData, setRefreshData] = useState(false);
  const [managingOrganizations, setManagingOrganizations] = useState([]);
  const [selectedChainId, setSelectedChainId] = useState();
  const [selectedChain, setSelectedChain] = useState();
  const [selectedOrganizationId, setSelectedOrganizationId] = useState();
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [allRoles, setAllRoles] = useState();
  const [chains, setChains] = useState([]);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [open, setOpen] = useState(false);
  const [networkMsg, setNetworkMsg] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [filterInput, setFilterInput] = useReducer(reducerState,{search: "", selectedRolls:[], selectedHotels:[]});
  const [userId, setUserId] = useState(null);
  const [userNameToDelete, setUserNameToDelete] = useState(null);
  const [userName, setUserName] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);

  const [downloadButton, setDownloadButton] = useState(false);
  const [dateDialog, setDateDialog] = useState(false);

  const handleCloseSatus = () => {
    setOpen(false);
  };
  const All = "All";
  const organizationId = hotelSelect?.organizationId;
  const managedBy = hotelSelect?.managedBy;
  let userRightsView = null,
    acrossHotel = null;
  const defaultOrgProps = {
    options: [
      ...managingOrganizations.map((org) => {
        return org.name;
      }),
    ],
    getOptionLabel: (option) => option,
  };
  const defaultChainProps = {
    options: [
      ...chains.map((chain) => {
        return chain.name;
      }),
      All,
    ],
    getOptionLabel: (option) => option,
  };

  useEffect(() => {
    if (token && hotelId) {
      window.scrollTo(0, 0);
      getRoles();
    } else {
      return;
    }
  }, [token, hotelId]);
  useEffect(() => {
    if (token && acrossHotel) {
      getManagingOrganization();
      setSelectedOrganizationId(hotelSelect?.managedBy);
      // // setSelectedOrganization(hotelSelect?.managedByName);
      setSelectedChainId(hotelSelect?.organizationId);
      setSelectedChain(All);
    }
  }, [token]);

  useEffect(() => {
    if (token && acrossHotel) {
      if (!selectedOrganizationId) {
        return;
      } else {
        getChains();
      }
    }
  }, [token, selectedOrganizationId]);

  useEffect(() => {
    if (token && acrossHotel) {
      if (!selectedOrganizationId || !selectedChainId) {
        return;
      } else {
        getUser();
        getHotels();
      }
    } else if (token && !acrossHotel) {
      getUser();
      getHotels();
    }
  }, [token, selectedChainId, selectedOrganizationId]);

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "userRightsView") {
        userRightsView = permission[key];
      }
      if (permission[key].name === "acrossHotel") {
        acrossHotel = permission[key];
      }
    }
  }

  async function getManagingOrganization() {
    setLoading(true);
    const { get } = await authFetch({
      path: `/all-managing-org`,
    });
    const { data, error } = await get();
    if (data) {
      setManagingOrganizations(data);
    } else {
      console.log(error);
    }
    setLoading(false);
  }

  async function getChains() {
    setLoading(true);
    const managingId = acrossHotel ? selectedOrganizationId : managedBy;
    const { get } = await authFetch({
      path: `/managing-org/${managingId}/chains`,
    });
    const { data, error } = await get();
    if (data) {
      setChains(data);
    } else {
      console.log(error);
    }
    setLoading(false);
  }
  async function getRoles() {
    setLoading(true);
    const { get } = await authFetch({
      path: `/all-roles`,
    });
    const { data, error } = await get();
    if (data) {
      setAllRoles(data);
    } else {
      console.log(error);
    }
    setLoading(false);
  }

  const handleOrgChange = (value) => {
    const orgId = managingOrganizations.find((org) => org.name === value);
    if (orgId) {
      setSelectedOrganizationId(orgId.id);
      setSelectedOrganization(orgId.name);
      setSelectedChainId(All.toLowerCase());
      setSelectedChain(All);
    } else {
      setSelectedOrganizationId(null);
    }
  };

  const handleChainChange = (value) => {
    if (value === All) {
      setSelectedChainId(All.toLowerCase());
      setSelectedChain(All);
      setUserList([]);
    } else {
      const chainId = chains.find((chain) => chain.name === value);
      if (chainId) {
        setSelectedChainId(chainId.id);
        setSelectedChain(chainId.name);
        setUserList([]);
      } else {
        setSelectedChainId(null);
      }
    }
  };

  const deleteUser = async (id) => {
    if (id) {
      setLoading(true);
      const { del } = await authFetch({
        path: `/deactivate-user/${id}`,
      });
      await del();
      getUser();

      setNetworkMsg("User Deleted Successfully");
      setOpen(true);
    }
  };

  const history = useHistory();
  const location = useLocation();

  const getUser = useCallback(
    async (value) => {
      setLoading(true);
      const managingId = acrossHotel ? selectedOrganizationId : managedBy;
      const id = acrossHotel ? selectedChainId : organizationId;
      const { get } = await authFetch({
        // path: `/managing-org/${managingId}/chains/${id}/users`,
        path: `/all-users?search=${value?.search ?? ""}&roleIds=${value?.selectedRolls?.map(({id})=>id)?.join(",")??""}&hotelIds=${value?.selectedHotels?.map(({id})=>id)?.join(",")??""}`,
      });
      const { data, error } = await get();

      if (data) {
        let hotelList;
        let i = 0;

        const users = data.map((user) => {
          // let permissionList = [];

          // if (user.permission?.canCreateUser === true) {
          //   permissionList.push("Can Create User");
          // }
          // if (user.permission?.canUploadData === true) {
          //   permissionList.push("Can Upload Data");
          // }
          // if (user.permission?.canCreateAlgorithm === true) {
          //   permissionList.push("Can Create Algorithm");
          // }
          // if (user.permission?.canCreateEvent === true) {
          //   permissionList.push("Can Create Event");
          //   i++;
          // }

          hotelList = user.hotels
            ?.map((hotel) => {
              return hotel.name;
            })
            .join(", ");
          return {
            ...user,
            hotelArray: user.hotels,
            hotels: hotelList,
          };
        });
        setUserList(users);
      } else if (data === null) {
        setUserList([]);
      } else {
        console.log(error);
      }
      setLoading(false);
    },
    [authFetch]
  );

  async function getHotels() {
    const managingId = acrossHotel ? selectedOrganizationId : managedBy;
    const id = acrossHotel ? selectedChainId : organizationId;
    const { get } = await authFetch({
      path: `/managing-org/all/chains/all/hotels/1`,
    });
    const { data, error } = await get();
    if (data) {
      setHotels(data);
      setError(false);
    } else {
      setError(true);
      console.log(error);
    }
  }

  function confirmation() {
    const val = window.confirm("Please confirm again");
    if (val) {
      deleteUser(userId);
      setUserId(null);
    }
    return;
  }

  const defaultUserNameProps = {
    options: userList,
    getOptionLabel: (option) => {
      return `${option?.name === undefined ? "" : option?.name} ${
        option?.username === undefined ? "" : `(${option?.username})`
      }`;
    },
  };

  const handleUserName = useCallback(
    (newList) => {
      const currentSearchParams = new URLSearchParams(history.location.search);
      currentSearchParams.set("search", newList.name.toString());
      history.replace({
        pathname: history.location.pathname,

        search: currentSearchParams.toString(),
      });
      setUserName(newList?.name);
      // setSearchParams(newList);
      if (newList === null) {
        setNewUserList(undefined);
      } else {
        setNewUserList([newList]);
      }
    },
    [userName]
  );

  const debouncer = useCallback(debounce(getUser, 500), [getUser]);


  const handleFilterChange = useCallback((name, values) => {
    const selectedValues = [
      ...(filterInput?.[name] ?? [])?.filter((option) => values?.includes(option)),
      ...values.filter((option) => !(filterInput?.[name] ?? [])?.includes(option))];
    debouncer({ ...filterInput, [name]: selectedValues });
    setFilterInput({ [name]: selectedValues });
    console.log(values, filterInput?.[name], selectedValues, name)
  }, [setFilterInput, filterInput, debouncer, allRoles, hotels])
  
  const handleSearchChange = useCallback(
    (event) => {
      const newSearchTerm = event.target.value;
      setFilterInput({search: newSearchTerm})
      debouncer({ ...filterInput, [event.target.name]: newSearchTerm });
    },
    [debouncer]
  );

  const handleClearSearch = useCallback(() => {
    setFilterInput({search: ""})
    debouncer({ ...filterInput, search: "" });
  }, [debouncer]);

  useEffect(() => {
    if (!newUserList) return;
    const selectedUser = userList.filter((user) =>
      newUserList.find(({ id }) => user.id === id)
    );
    setNewUserList(selectedUser);
  }, [userList]);

  useEffect(() => {
    if (newUserList !== undefined) {
      setNewUserList(undefined);
      setUserName(undefined);
    }
  }, [selectedChain]);

  const handleCheckboxChange = (email, username) => {
    setSelectedEmails((prevSelectedRows) => {
      const isSelected = prevSelectedRows?.some((row) => row === email);
      if (isSelected) {
        return prevSelectedRows?.filter((row) => row !== email);
      } else {
        return [...prevSelectedRows, email];
      }
    });
    setUserName((prevSelectedRows) => {
      const isSelected = prevSelectedRows?.some((row) => row === username);
      if (isSelected) {
        return prevSelectedRows?.filter((row) => row !== username);
      } else {
        return [...prevSelectedRows, username];
      }
    });
  };

  // useEffect(() => {
  //   const searchTimeout = setTimeout(() => {
  //     getUser(searchInput);
  //     console.log(`Searching for: ${searchInput}`);
  //   }, 300);
  //   return () => clearTimeout(searchTimeout);
  // }, [searchInput]);

  return !Error ? (
    <>
      {userRightsView ? (
        <div>
          <PageTitle>
            <H>Users</H>
          </PageTitle>

          <TableContainer>
            {/* {acrossHotel && ( */}
              <HeadSection>
                <Dialog
                  open={shouldBlockNavigation}
                  onClose={() => setShouldBlockNavigation(false)}
                  maxWidth="lg"
                >
                  <DialogTitle>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Label>{`Are you sure you want to delete user - ${userNameToDelete} ?`}</Label>
                      <Cross onClick={() => setShouldBlockNavigation(false)}>
                        &times;
                      </Cross>
                    </div>
                  </DialogTitle>
                  <DialogActions
                    style={{
                      justifyContent: "space-evenly",
                      paddingBottom: 16,
                    }}
                  >
                    <PrimaryButton
                      autoFocus
                      next
                      onClick={() => {
                        setShouldBlockNavigation(false);
                        confirmation();
                      }}
                    >
                      Yes
                    </PrimaryButton>
                    <PrimaryButton
                      next
                      onClick={() => {
                        setShouldBlockNavigation(false);
                        setUserId(null);
                      }}
                    >
                      No
                    </PrimaryButton>
                  </DialogActions>
                </Dialog>
                <Stack
                  direction={"row"}
                  style={{ width: "60%" }}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  {/* <Autocomplete
                      {...defaultUserNameProps}
                      value={userName}
                      style={{ width: "25%", padding: "0 8px 0 8px" }}
                      freeSolo={true}
                      disableClearable={false}
                      onChange={(event, newValue) => {
                        handleUserName(newValue);
                      }}
                      classes={{
                        paper: classes.dropdownStyle,
                      }}
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            label="Users"
                            variant="standard"
                          />
                        </>
                      )}
                    /> */}
                  <Stack width={"calc(100% - 100px)"} direction={"row"} gap={2}>
                    <FormControl size="small" sx={{ width: 200, minWidth: 170 }} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-search">Search</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-search"
                        value={filterInput?.search??""}
                        name="search"
                        onChange={handleSearchChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={handleClearSearch}>
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Search"
                      />
                    </FormControl>
                    {permission.some(({name})=>name==="ViewUserByRole") ? <Autocomplete
                      multiple
                      size="small"
                      id="fixed-tags-demo"
                      value={filterInput.selectedRolls??[]}
                      onChange={(_, newValue) => {
                        handleFilterChange("selectedRolls",newValue);
                      }}
                      options={allRoles??[]}
                      getOptionLabel={(option) => option.description}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              {...tagProps}
                              size="small"
                              key={option.id+option.name}
                              label={option.description}
                            />
                          );
                        })
                      }
                      style={{ width: 200, maxWidth: 200 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: null,
                          }}
                          sx={{
                            label: {
                              background: "#f5f4f8",
                            },
                            ".MuiAutocomplete-inputRoot": {
                              border: "1px solid rgba(0, 0, 0, 0.23)",
                              ".&:hover": {
                                border: "1px solid #1976D2",
                              },
                              width: 200,
                              overflowX: "auto",
                              flexWrap: "nowrap",
                              "&::-webkit-scrollbar": {
                                display: "none",
                              },
                              " -ms-overflow-style": "none",
                              " scrollbar-width": "none",
                              input: {
                                minWidth: 100,
                              },
                              fieldset: {
                                display: "none",
                              },
                            },
                            ".MuiAutocomplete-inputRoot.Mui-focused": {
                              border: "1px solid #1976D2",
                            }
                          }}
                          label="User Roles"
                          placeholder=""
                        />
                      )}
                    /> : null}
                    <Autocomplete
                      multiple
                      size="small"
                      id="fixed-tags-demo"
                      value={filterInput.selectedHotels??[]}
                      onChange={(_, newValue) => {
                        handleFilterChange("selectedHotels",newValue);
                      }}
                      options={hotels??[]}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              {...tagProps}
                              size="small"
                              key={option.id+option.name}
                              label={option.name}
                            />
                          );
                        })
                      }
                      style={{ width: 200, maxWidth: 200 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: null,
                          }}
                          sx={{
                              label: {
                              background: "#f5f4f8",
                              },
                            ".MuiAutocomplete-inputRoot": {
                              border: "1px solid rgba(0, 0, 0, 0.23)",
                              width: 200,
                              overflowX: "auto",
                              flexWrap: "nowrap",
                              "&::-webkit-scrollbar": {
                                display: "none",
                              },
                              " -ms-overflow-style": "none",
                              " scrollbar-width": "none",
                              input: {
                                minWidth: 100,
                              },
                              fieldset: {
                                display: "none",
                              },
                            },
                            ".MuiAutocomplete-inputRoot.Mui-focused": {
                              border: "1px solid #1976D2",
                            }
                          }}
                          label="Hotel Names"
                          placeholder=""
                        />
                      )}
                    />
                  </Stack>

                  {downloadButton ? (
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: "18px",
                        lineHeight: "1.25",
                        color: "#333333",
                      }}
                    >
                      Please select users
                    </Typography>
                  ) : null}
                </Stack>
              </HeadSection>
            {/* )} */}
            {!Loading ? (
              <UserListTable
                userList={newUserList === undefined ? userList : newUserList}
                chains={chains}
                hotels={hotels}
                setError={setError}
                setLoading={setLoading}
                setRefreshData={setRefreshData}
                getUser={getUser}
                selectedChain={selectedChain}
                allRoles={allRoles}
                deleteUser={deleteUser}
                setUserId={setUserId}
                setShouldBlockNavigation={setShouldBlockNavigation}
                setUserNameToDelete={setUserNameToDelete}
                setOpen={setOpen}
                setNetworkMsg={setNetworkMsg}
                setFilterInput={setFilterInput}
                selectedEmails={selectedEmails}
                setSelectedEmails={setSelectedEmails}
                handleCheckboxChange={handleCheckboxChange}
                downloadButton={downloadButton}
                setDownloadButton={setDownloadButton}
                dateDialog={dateDialog}
                setDateDialog={setDateDialog}
                userName={userName}
                setUserName={setUserName}
              />
            ) : (
              <LoadingPage></LoadingPage>
            )}
          </TableContainer>
        </div>
      ) : (
        <NoAccess></NoAccess>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseSatus}
      >
        {networkMsg !== "User Added Successfully" &&
        networkMsg !== "User Updated Successfully" &&
        networkMsg !== "User Deleted Successfully" ? (
          <SnackbarContent
            style={{ backgroundColor: "#CA3433" }}
            message={networkMsg}
          />
        ) : (
          <SnackbarContent
            style={{ backgroundColor: "#228b22" }}
            message={networkMsg}
          />
        )}
      </Snackbar>
    </>
  ) : (
    <ErrorPage></ErrorPage>
  );
}
