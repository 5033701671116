import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BASE_URL,
  getISODate,
  onUniqueProp,
  useAuth,
} from "./../../sdk";

export function useEditDemandLevel(refreshPriceRecommendations) {
  const [demandLevels, setDemandLevels] = useState([]);
  const [isSetModalOpen, setSetModalOpen] = useState(false);
  const [editDemandData, setEditDemandData] = useState(false);
  const { hotelId } = useParams();
  const { token, authFetch } = useAuth();
    
  useEffect(() => {
    fetchDemandLevels(hotelId);
  },[])

  async function updateBaseDemandLevels(bdls=[]) {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/base-demand-level/all`,
    });
    const { data, response, error } = await post(
      bdls.filter(isValid).map((bDL) => {
        return {
          ...bDL,
          date: getISODate(bDL.date),
        };
      })
    );
    if (!response?.ok) {
    //   setnetworkMsg(data?.messageToUser || "Something went wrong");
      console.log(error);
    }
  }

  async function fetchDemandLevels(anotherHotelId = false) {
    let dLevels = [];
    const hotelIdToUse = anotherHotelId ? anotherHotelId : hotelId;
    const { get } = await authFetch({
      path: `/hotel/${hotelIdToUse}/city-demand-level`,
    });
    const { data, error } = await get();
    if (data) {
      dLevels = data;
    } else {
      console.log(error);
    }
    setDemandLevels(dLevels);
  }
  function isValid(bDL) {
    return bDL.demandLevelSymbol !== null;
    }
    
  async function setBaseDemandLevelsfromModel(bdls) {
    await fetch(`${BASE_URL}/hotel/${hotelId}/base-demand-level/set`, {
      headers: {
        token,
      },
      method: "POST",
      body: JSON.stringify(
        bdls.filter(isValid).map((bDL) => {
          return {
            ...bDL,
            date: getISODate(bDL.date),
          };
        })
      ),
    });
  }
  async function onSave(
    optionsArray,
    fromEvent = false
  ) {
    const dateDemandLevelMap = {};
    for (const row of optionsArray) {
      const {
        startDate,
        endDate,
        days,
        demandLevelSymbol,
        hotelDemandLevelSymbol,
        cityDemandLevelSymbol,
        description,
        events,
        manualEvents,
        overrideSymbol,
        cityOverrideSymbol,
      } = row;
      const numberOfDaysFromStartToEndDate =
        (new Date(endDate).getTime() - new Date(startDate).getTime()) /
          (1000 * 3600 * 24) +
        1;
      const numberOfDaysFromStartToEndDateINT = Math.round(
        numberOfDaysFromStartToEndDate
      );

      for (let i = 0; i < numberOfDaysFromStartToEndDateINT; i++) {
        const dateInContext = new Date(startDate);
        dateInContext.setDate(dateInContext.getDate() + i);
        const dayOfDateInContext = dateInContext.getDay();
        const isDaySelected =
          days.findIndex((d) => dayOfDateInContext === d) !== -1;
        if (isDaySelected) {
          const displayDate = dateInContext.toLocaleDateString("en-GB");
          dateDemandLevelMap[displayDate] = {
            date: dateInContext,
            displayDate,
            demandLevelSymbol,
            hotelDemandLevelSymbol,
            cityDemandLevelSymbol,
            description,
            events,
            manualEvents,
            overrideSymbol,
            cityOverrideSymbol,
          };
        }
      }
    }
    if (!fromEvent) {
      await setBaseDemandLevelsfromModel(Object.values(dateDemandLevelMap));
      refreshPriceRecommendations();
      return;
    }
    const overridedDemandLevelsMergedWithAlreadyExistingData = [
      ...Object.values(dateDemandLevelMap)
    ]
      .filter(onUniqueProp("displayDate"))
      .sort((a, b) => {
        const [dateA, monthA, yearA] = a.displayDate.split("/");
        const [dateB, monthB, yearB] = b.displayDate.split("/");
        return (
          Date.parse(`${monthA}/${dateA}/${yearA}`) -
          Date.parse(`${monthB}/${dateB}/${yearB}`)
        );
      });
    await updateBaseDemandLevels(
      overridedDemandLevelsMergedWithAlreadyExistingData
    );
  }

    return {
        demandLevels,
        isSetModalOpen,
        editDemandData,
        setSetModalOpen,
        setEditDemandData,
        onSave
  };
}
